// import { useContext } from "react";
// import { DialogContext } from "./Context/DialogContext";
import "./Offer.css"
import Modal from './Modal'
import Error from './Error'
import Success from './Success'
import {useState, useRef, useContext} from "react";
import { useNavigate } from 'react-router-dom';
import {UserContext} from "./Context/UserContext";

export default function Offer({_id, title, type, short_desc, points, maxMass, minMass, immediate, link, logo, background, notes, steps, getStars, getEnergy, fetchOffer, notLoggedIn, processing}){
    // const { setDialog } = useContext(DialogContext)
    const [offerOpen, setOfferOpen] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)
    const planet = useRef("")
    const navigate = useNavigate()
    const {setStatus, setUserInfo} = useContext(UserContext)

    async function goToOffer() {
        if (notLoggedIn) {
            return navigate("/login")
        }

        // if there is a link open it
        link && window.open(link, '_blank')
        setOfferOpen(false)
        const response = await fetch(process.env.REACT_APP_API_URL + '/earn', {
            method: 'POST',
            body: JSON.stringify({ "offer_id": _id }),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include' // cookies will be considered credentials
        });
        if (response.ok) {
            //json is async
            const message = await response.json()
            if (message.status === "Confirmed"){
                const successMessage = message.energyChange ? 
                    `Nice! You earned ${message.energyChange} energy.` :
                    "New planet discovered! Availible now in your galaxy."
                planet.current = message.planet
                setSuccessMsg({title: "Success", desc: successMessage})
                getStars()
                getEnergy()
            }
        } else {
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setErrorMsg({title: "Oops", desc: message})
            }
        }
    }

    async function goToPlanet() {
        navigate(`/planet/${planet.current}`)
    }

    function toLogin() {        
        navigate("/login")
    }

    return (
        <>
            {/* reward details modal */}
            <Success successMsg={successMsg} setSuccessMsg={setSuccessMsg} customClick={type === "planet" ? goToPlanet : fetchOffer} customClose={fetchOffer} buttonText={type === "planet" ? "Go" : "Okay"}/>
            <Error errorMsg={errorMsg} setErrorMsg={setErrorMsg} customClick={fetchOffer} customClose={fetchOffer}/>
            <Modal open={offerOpen} onClose={() => setOfferOpen(false)}>
                <div className="offer-modal-inner-wrapper">
                    <div className="offer-modal-top" style={{background: background}}>
                        <div className="left">
                            <img src={`${process.env.REACT_APP_API_URL}${logo}`} alt="Logo" className="offer-logo-modal"/>
                            <div className="main">
                                <div className="title">{title}</div>
                                <div className="short-desc">{short_desc}</div>
                            </div>
                        </div>
                        { type === "points" && <div className="right">
                            <div className="points-number-wrapper">
                                <svg width="40" height="66" viewBox="0 0 24 24" fill="none" strokeWidth={1.5} stroke="currentColor" xmlns="http://www.w3.org/2000/svg" className="offer-modal-energy">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                                </svg> {points}
                            </div>
                            <div className="points-text">Energy</div>                        
                        </div>}
                        { type === "planet" && <div className="right">
                            <div className="points-number-wrapper">
                                <svg width="40" height="66" viewBox="0 0 24 24" fill="none" strokeWidth={1.5} stroke="currentColor" xmlns="http://www.w3.org/2000/svg" className="offer-modal-energy">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                                </svg>{minMass !== maxMass ? `${minMass} - ${maxMass}` : minMass}
                            </div>
                            <div className="points-text">Core Mass</div>                        
                        </div>}
                    </div>
                    <div className="offer-modal-bottom">
                        <div className="steps">
                            {steps.length > 0 && steps.map((step, index) => (
                                <>  
                                    <div className="step">
                                        <div className="number">{index + 1}</div>
                                        <div>{step}</div>
                                        <div className="new-line"></div>
                                    </div>
                                    {index !== steps.length - 1 && 
                                        <div className="step-connect">
                                            <div className="line-wrapper">
                                                <div className="line"></div>
                                            </div>
                                        </div>
                                    }
                                </>
                            ))}
                        </div>
                        { notes && <div className="notes">{notes}</div>}
                        <div className="button" style={{background: background}} onClick={() => goToOffer()}> {type === "points" ? <>Earn 
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="modal-bottom-energy">
                                 <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                            </svg>{points}</>: <>Go to offer</>}                      
                        </div>
                        <button className="cancel" onClick={() => setOfferOpen(false)}>Cancel</button>
                    </div>
                </div>
            </Modal>
            <div className="offer" onClick={() => steps && steps.length ? setOfferOpen(true) : notLoggedIn ? toLogin() : goToOffer()}>

                <div className="offer-image" style={{background: background}}>
                    {logo && <img src={`${process.env.REACT_APP_API_URL}${logo}`} alt="Logo" className="offer-logo"/>}
                </div>
                <div className="offer-texts">
                    <div className="offer-top">
                        <div className="offer-title">{title}</div>
                        <div className="offer-desc">{short_desc}</div>
                    </div>
                    <div className="offer-bottom">
                        {type === "planet" && <div className="offer-bottom-wrapper">
                            <div className="offer-bottom-title">Planet Mass</div>
                            <div className="offer-bottom-value">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="offer-bottom-icon">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                                </svg>{minMass !== maxMass ? `${minMass} - ${maxMass}` : minMass}
                            </div>
                        </div>}
                        {type === "points" && <div className="offer-bottom-wrapper">
                            <div className="offer-bottom-title">Energy</div>
                            <div className="offer-bottom-value">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="offer-bottom-icon">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                                </svg>{points}
                            </div>
                        </div>}
                        <div className="offer-bottom-wrapper">
                            <div className="offer-bottom-title">Processing</div>
                            <div className="offer-bottom-value">{immediate ? "Instant" : processing}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
import './App.css'
import Layout from './Layout'
import LoginPage from './pages/loginPage'
import RegisterPage from './pages/registerPage'
import ConfirmationPage from './pages/ConfirmationPage'
import {Route, Routes} from 'react-router-dom'
import {UserContextProvider} from './Context/UserContext'
import IndexPage from './pages/IndexPage'
import RequireAuth from './RequireAuth'
import Authenticate from './Authenticate'
import RatingPage from './pages/RatingPage'
import MyGalaxyPage from './pages/MyGalaxyPage'
import PlanetPage from './pages/PlanetPage'
import MergePage from './pages/MergePage'
import AccountPage from './pages/AccountPage'
import OfferPage from './pages/OfferPage'
import RewardPage from './pages/RewardPage'
import OfferDisplayPage from './pages/OfferDisplayPage'
import RewardDisplayPage from './pages/RewardDisplayPage'
import AdminOfferPage from './pages/AdminOfferPage'
import AdminRewardPage from './pages/AdminRewardPage'
import AdminGiftCardPage from './pages/AdminGiftCardPage'


function App() {
  return (
    <UserContextProvider>
      <Authenticate/>
      <Routes>
        <Route path="/login" element={<LoginPage/>} />
        <Route path="/register" element={<RegisterPage/>} />
        <Route path="/confirmation/:token" element={<ConfirmationPage/>} />

        <Route element={<Layout/>}>
          <Route index element={<IndexPage />} />
          <Route path="/offers/display" element={<OfferDisplayPage/>} />
          <Route path="/rewards/display" element={<RewardDisplayPage/>} />
          <Route element={<RequireAuth />}>
            <Route path="/rating" element={<RatingPage/>}/>
            <Route path="/galaxy" element={<MyGalaxyPage/>}/>
            <Route path="/offers" element={<OfferPage/>} />
            <Route path="/rewards" element={<RewardPage/>} />
            <Route path="/planet/:id" element={<PlanetPage/>} />
            <Route path="/merge/:id" element={<MergePage/>} />
            <Route path="/account" element={<AccountPage/>} />
            <Route path="/admin/offers" element={<AdminOfferPage/>} />
            <Route path="/admin/rewards" element={<AdminRewardPage/>} />
            <Route path="/admin/giftcards" element={<AdminGiftCardPage/>} />
          </Route>
        </Route>
      </Routes>
    </UserContextProvider>
  )
}

export default App;

import "./MyGalaxyPage.css"
import {useContext, useState, useEffect} from 'react'
import {UserContext} from "../Context/UserContext"
import {Link, useNavigate, Navigate} from "react-router-dom"
import Planet from "../Planet"
import Galaxy from '../images/galaxy.png'
import Loading from "../Loading"
import Error from '../Error'

export default function MyGalaxyPage() {
    // set to null at start, becomes [] if no planets
    const [planets, setPlanets] = useState(null)
    const [hover, setHover] = useState(null)
    const [errorMsg, setErrorMsg] = useState(false)
    const { setStatus, setUserInfo } = useContext(UserContext); //re-renders when logout
    const navigate = useNavigate()
    // limit to 6 planets
    const planetFormat = [
        { position: "left", duration: "30s" },
        { position: "bottom", duration: "50s" },
        { position: "right", duration: "40s" },
        { position: "top", duration: "60s" },
        { position: "left", duration: "75s" },
        { position: "bottom", duration: "90s" },
    ]

    useEffect(() => {
        const fetchPlanets = async () =>{
            const response = await fetch(process.env.REACT_APP_API_URL + `/planets`, {
            credentials: 'include',}).catch((error) => {
                console.log(error)
            })
            if (response.ok) {
                const planetsDoc = await response.json()
                // console.log(planetsDoc)
                setPlanets(planetsDoc)
            } else{
                const message = await response.json();
                if (message === "not logged in"){
                    setStatus("not logged in")
                    setUserInfo(null)
                }
            }
        }
        fetchPlanets()
    }, [])

    const handleMerge = (planetId) => {
        const planetCount = planets.filter(planet => planet.status === 'activated').length
        if (planetCount > 1){
            navigate(`/merge/${planetId}`)
        } else {
            setErrorMsg({title: "Oops", desc: "You need 2 or more planets to start a merge."})
        }
    }

    const getLeft = (pos) => {
        if (pos === "left"){
            return "0%"
        } else if (pos === "right"){
            return "100%"
        } else {
            return "50%"
        }
    }

    const getTop = (pos) => {
        if (pos === "top"){
            return "0%"
        } else if (pos === "bottom"){
            return "100%"
        } else {
            return "50%"
        }
    }   

    return (
        planets ?
        <div className="opening hide-UI view-3D zoom-large my-galaxy">
            <Error errorMsg={errorMsg} setErrorMsg={setErrorMsg} />
            <div id="menu">
                <div id="menu-inner-container">
                {planets.length > 0 && planets.map((planet) => (
                    <div key={planet._id + "key"} className="menu-planet"
                        onMouseEnter={() => setHover(planet._id)}
                        onMouseLeave={() => setHover(null)}
                    >
                        {hover === planet._id ?
                            planet.status === "activated" ? 
                                <div className="planet-action-menu">
                                    <Link to={`/planet/${planet._id}`} className="planet-action">Visit</Link>
                                    <div onClick={() => {handleMerge(planet._id)}} className="planet-action">Merge</div>
                                    {/* {planets.length > 1 ? <Link to={`/merge/${planet._id}`} className="planet-action">Merge</Link>
                                        : <div onClick={() => {setErrorMsg({title: "Oops", desc: "You need 2 or more planets to start a merge."})}}className="planet-action">Merge</div>
                                    } */}
                                </div> :
                                <div className="planet-action-menu">
                                    <Link to={`/planet/${planet._id}`} className="planet-action">Visit</Link>
                                </div>
                            : planet.status !== "activated" && <div className="planet-new">New!</div>
                        }
                        <div className="planet-name-container">{planet.name ? planet.name : "Unknown"}</div>
                    </div>
                ))}
                </div>              
            </div>
            <div id="universe" className="scale-stretched">
                <img src={Galaxy} alt="Image description" className="background"/>
                <div id="galaxy">
                    <div id="planets" >
                        {/* only show activated planets */}
                        {planets.length > 0 && planets.filter((planet) => planet.status === "activated").map((planet, index) => (
                            <div key={planet._id} className="orbit" style={{width: `${index * 20 + 40}em`, height: `${index * 20 + 40}em`, marginTop: `${-index * 10 - 20}em`, marginLeft:`${-index * 10 - 20}em`, zIndex: `${8 - index}`, animationDuration: planetFormat[index].duration}}>
                                <div className="pos" style={{animationDuration: planetFormat[index].duration, 
                                    left: getLeft(planetFormat[index].position) , top: getTop(planetFormat[index].position) }}>
                                    <Planet planetInfo={planet} fullView={false} animationDuration={planetFormat[index].duration} hover={hover} shadow={planetFormat[index].position}/>
                                </div>
                            </div>
                        ))}
                        {/* <div id="planet6" className="orbit">
                            <div className="pos">
                            <div className="planet">
                                <div className="ring"></div>
                                <dl className="infos">
                                <dt>Planet6</dt>
                                <dd><span></span></dd>
                                </dl>
                            </div>
                            </div>
                        </div> */}
                    
                        {/* <div id="galaxy-center">
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
        : <Loading text="traveling to your galaxy"/>
    )
}


import {useEffect, useState, useRef, useContext} from "react";

export default function AdminRewardPage(){
    const [transactions,setTransactions] = useState([])
    const [transId, setTransId] = useState('');
    const [code, setCode] = useState('');
    const [pin, setPin] = useState('');
    const [expires, setExpires] = useState('');

    useEffect(() => {
        getConfirmed()
    }, []);

    // fetch and json is async
    const getConfirmed = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/get-confirmed-rewards', {credentials: 'include',})
        if (response.ok) {
            response.json().then(trans => {
                setTransactions(trans)
            })
        } else{
            console.log("not logged in")
        }
    }

    const issueReward = async (ev) =>{
        ev.preventDefault() //no freshing
        const response = await fetch(process.env.REACT_APP_API_URL + '/issue-reward', {
            method: 'POST',
            body: JSON.stringify({transId, code, pin, expires}),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        if (response.ok) {
            getConfirmed()
            console.log("confirmed")
        } else{
            getConfirmed()
            console.log("error")
        }
    }


    return (
        <div style={{ color: "white", paddingTop: "100px"}}>
            <form onSubmit={issueReward}>
                transId
                <input type="text" 
                    value={transId}
                    onChange={ev => setTransId(ev.target.value)}/>
                code
                <input type="text" 
                    value={code}
                    onChange={ev => setCode(ev.target.value)}/>
                pin
                <input type="text" 
                    value={pin}
                    onChange={ev => setPin(ev.target.value)}/>
                expires
                <input type="text" 
                    value={expires}
                    onChange={ev => setExpires(ev.target.value)}/>
                <button>issue gift card</button>
            </form>
            {transactions.length > 0 && transactions.map(transaction => (
                <div key={transaction._id}>  
                    id:
                    <div>{transaction._id}</div>
                    type:
                    <div>{transaction.type}</div>
                    reward:
                    <div>{transaction.reward.title}</div>
                    <div>{transaction.reward.points}</div>
                    <div>{transaction.reward.amount}</div>
                    energyChange:
                    <div>{transaction.energyChange}</div>
                    starsChange:
                    <div>{transaction.starsChange}</div>
                    planet:
                    <div>{transaction.planet}</div>
                    status:
                    <div>{transaction.status}</div>
                    user:
                    <div>{transaction.user}</div>
                    createAt:
                    <div>{transaction.createdAt}</div>
                    updated:
                    <div>{transaction.updatedAt}</div>
                    ===============
                </div>
            ))}
        </div>
    )
}
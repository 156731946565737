import Logo from './images/planetmerge.svg'
import LogoIcon from './images/planetmerge_icon.svg'
import { useContext, useEffect, useState} from "react"
import { Navigate, Link} from "react-router-dom"
import { UserContext } from "./Context/UserContext"
// import  Skillglance from './images/skillglance.svg';

export default function Header() {
    const {setUserInfo, userInfo, status, setStatus, energy, setEnergy, stars, setStars} = useContext(UserContext);
    const [starFlash, setStarFlash] = useState(false); // stars
    const [energyFlash, setEnergyFlash] = useState(false); // energy

    const [oldStars, setOldStars] = useState(null); // last stars value
    const [oldEnergy, setOldEnergy] = useState(null); // last energy value
    // const [color, setColor] = useState(false)

    // useEffect(() => {
    //     const changeColor = () => {
    //         if (window.scrollY >= 10){
    //             setColor(true)
    //         } else {
    //             setColor(false)
    //         }
    //     }
    //     // Add event listener on mount
    //     window.addEventListener("scroll", changeColor)
    //     // Remove event listener on unmount
    //     return () => {
    //       window.removeEventListener("scroll", changeColor);
    //     };

    // }, []);

    useEffect(() => {
        if (userInfo){
            getStars()
            getEnergy()
        }
    }, [userInfo])

    // fetch and json is async
    const getEnergy = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/get-energy', {credentials: 'include',})
        if (response.ok) {
            response.json().then(energy => {
                setEnergy(energy)
            })
        } else{
            const message = await response.json()
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
        }
    }

    // fetch and json is async
    const getStars = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/get-stars', {credentials: 'include',})
        if (response.ok) {
            response.json().then(stars => {
                setStars(stars)
            })
        } else{
            const message = await response.json()
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
        }
    }
    

    async function logout() {
        const response = await fetch(process.env.REACT_APP_API_URL + '/logout', {
            credentials: 'include',
            method: 'POST',
        })
        if (response.ok) {
            setStatus("not logged in")
            setUserInfo(null)
        } else {
            const message = await response.json()
            if (message === "not logged in"){
                setStatus("not logged in")
            } else {
                console.log("logout failed")
            } 
        }
    }

    useEffect(() => {
        if (stars !== oldStars){
            setStarFlash(true)
            setOldStars(stars)
        }  
    },[stars])

    useEffect(() => {
        if (energy !== oldEnergy){
            setEnergyFlash(true)
            setOldEnergy(energy)
        }
    },[energy])
    
    useEffect(() => {
        if (starFlash){
            const interval = setInterval(() => {
                setStarFlash(false);
            }, 500);
          
            return () => clearInterval(interval);
        }

    }, [starFlash]);

    useEffect(() => {
        if (energyFlash){
            const interval = setInterval(() => {
                setEnergyFlash(false);
            }, 500);
          
            return () => clearInterval(interval);
        }

    }, [energyFlash]);
    
    const username = userInfo?.username

    return (
        <div className="header-container">
            <header className="header">
                <Link to="/" id="logo-wrapper">
                    <img src={Logo} alt="logo" className='logo'/>
                    <img src={LogoIcon} alt="logo-icon" className='logo-icon'/>
                </Link>
                <nav>
                
                { // if there is username
                    username && (
                        <div className='header-middle-logged-in'>
                            <Link to="/galaxy" className="link">My Galaxy</Link>
                            <Link to="/offers" className="link">Earn</Link>
                            <Link to="/rewards" className="link">Redeem</Link>
                            {/* <a onClick={logout}>Logout</a> */}
                        </div>
                )}
                { // if there isn't an username
                    !username && (
                        <div className='header-middle'>
                            <div className='header-middle-display'>
                                <Link to="/offers/display" className="link">Earn</Link>
                                <Link to="/rewards/display" className="link">Redeem</Link>
                            </div>
                            <Link to="/login" className="link">Login</Link>
                            <Link to="/register" className="link">Register</Link>
                        </div>
                )}
                </nav>
                { username &&
                    <div className='header-right'>
                        <div className={energyFlash ? "points-wrapper flash" : "points-wrapper"}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="icon">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                            </svg>
                            {energy}
                        </div>
                        {/* <div className={stars ? "points-wrapper wrapper-active" : "points-wrapper"} > */}
                        <div className={starFlash ? "points-wrapper flash" : "points-wrapper"}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="icon">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                            </svg>
                            {stars}
                        </div>
                        {/* <button className='header-circle'>
                            <div>{username && username[0].toUpperCase()}</div>
                        </button> */}
                        <Link to="/account" className="name-wrapper">{username}</Link>
                    </div>
                }
            </header>
            { username ? <div className='bottom-menu'>
                <Link to="/offers" className='bottom-menu-item'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="bottom-icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                    </svg>
                    Earn
                </Link>                
                <Link to="/rewards" className='bottom-menu-item'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="bottom-icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                    </svg>
                    Redeem
                </Link>
                <Link to="/galaxy" className='bottom-menu-item'>
                    <svg viewBox="0 0 24 24" fill="none" strokeWidth={1.5} stroke="currentColor" xmlns="http://www.w3.org/2000/svg" className="bottom-icon">
                        <circle cx="17.0066" cy="12.7699" r="1.47245" transform="rotate(-46.845 17.0066 12.7699)" />
                        <path d="M15.6865 14.1823C10.6761 19.5262 5.10001 23.7747 3.92835 22.6761C3.3037 22.0905 4.34428 19.8035 5.79326 17.48M17.0055 5.60789C19.2173 4.02663 21.4505 2.74093 22.0725 3.32412C22.9547 4.15124 20.6216 7.7496 17.6651 11.5441" />
                        <circle cx="13" cy="13.0002" r="8.44716" transform="rotate(-46.845 13 13.0002)" />
                    </svg>
                    my galaxy
                </Link>
                <Link to="/account" className='bottom-menu-item'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="bottom-icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                    Account
                </Link>
                <div onClick={logout} className='bottom-menu-item'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="bottom-icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                    </svg>
                    Logout
                </div>
            </div> : 
            <div className='bottom-menu'>
                <Link to="/offers/display" className='bottom-menu-item'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="bottom-icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                    </svg>
                    Earn
                </Link>                
                <Link to="/rewards/display" className='bottom-menu-item'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="bottom-icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                    </svg>
                    Redeem
                </Link>
            </div>            
            }
        </div>
    )

}
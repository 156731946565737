import { useRef, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom"
import VanillaTilt from 'vanilla-tilt';
import "./Reward.css"
import Error from './Error'
import Success from './Success'
import Confirm from './Confirm'
import {UserContext} from "./Context/UserContext";

export default function Reward({_id, title, points, background, getStars, fetchReward, getPremiumExp, logo, amount, notLoggedIn, quotaLeft, terms}){
    const [errorMsg, setErrorMsg] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)
    const [confirmMsg, setConfirmMsg] = useState(false)
    const tilt = useRef(null)
    const {setStatus, setUserInfo} = useContext(UserContext)
    const navigate = useNavigate()
    const options = {
        scale: 1.1,
        speed: 3000,
        max: 20,
        glare: true,
        "max-glare": 0.8,
    }
  
    useEffect(() => {
      VanillaTilt.init(tilt.current, options);
    }, [options]);

    async function handleRewardClick() {        
        const response = await fetch(process.env.REACT_APP_API_URL + '/redeem', {
            method: 'POST',
            body: JSON.stringify({ "reward_id": _id }),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include' // cookies will be considered credentials
        });
        if (response.ok) {
            setSuccessMsg({title: "Success", desc: `Thank you for redeeming, your ${title} giftcard will be delivered soon.`})
            getStars() // Get points again to check of changes
            fetchReward()
        } else {
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setErrorMsg({title: "Oops", desc: message})
            }
        }
    }

    function toLogin() {        
        navigate("/login")
    }

    return (
        <>
            <Success successMsg={successMsg} setSuccessMsg={setSuccessMsg} customClose={fetchReward} buttonText="Okay"/>
            <Error errorMsg={errorMsg} setErrorMsg={setErrorMsg} />
            <Confirm confirmMsg={confirmMsg} setConfirmMsg={setConfirmMsg} customClick={handleRewardClick} buttonText="Confirm"/>
            <div ref={tilt} style={{background: background}} className="reward" 
                onClick={() => notLoggedIn ? toLogin() : quotaLeft < 1 ? null : setConfirmMsg({title: "Reminder", desc: terms ? terms : "This reward is non-refundable, are you sure?"})}>
                <div className="reward-top">
                    <div className="reward-top-left">
                        <div className="reward-title">${(amount / 100).toFixed(2)}</div>
                        <div className="reward-points">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="reward-star">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                            </svg>
                            {points} {points === 1? "point":"points"}
                        </div>
                    </div>
                    <div className="reward-top-right">
                        {(amount - points) / amount > 0 && <div className="reward-discount">{`${Math.round( 100 * (amount - points) / amount) }% off`}</div>}
                    </div>
                </div>
                <div className="reward-middle">{quotaLeft < 1 ? "Sold Out" : ""}</div>
                <div className="reward-bottom">
                    <img src={`${process.env.REACT_APP_API_URL}${logo}`} alt="Logo" className="reward-logo"/>
                </div>
            </div>
        </>

    )
}
// import { useContext } from "react";
// import { DialogContext } from "./Context/DialogContext";
import ConfirmImg from './images/confirm.svg';
import Modal from './Modal'
import './Modal.css'

export default function Confirm({confirmMsg, setConfirmMsg, customClick, customClose, buttonText}){

    function handleClose() {
        // will call fetch offer and remove this modal immediately
        customClose && customClose()
        setConfirmMsg(null)
    }

    function handleClick(){
        customClick && customClick()
        setConfirmMsg(null)        
    }

    return (
        <Modal open={confirmMsg} onClose={() => handleClose()}>
            {confirmMsg &&
                <div className="success-confirm-modal-inner-wrapper">
                    <img src={ConfirmImg} alt="success image" className="error-modal-image"/>
                    <div className="error-modal-title">{confirmMsg.title}</div>
                    <div className="error-modal-text">{confirmMsg.desc}</div>
                    <button className="error-modal-button success-modal-button" onClick={() => handleClick()}>{buttonText}</button>
                    <button className="cancel" onClick={() => handleClose()}>Cancel</button>
                </div>
            }
        </Modal>
    )
}
import Offer from "../Offer";
import {useEffect, useState, useContext} from "react";
import {UserContext} from "../Context/UserContext";
import { Navigate } from "react-router-dom"
import "./OfferPage.css"

export default function OfferPage() {
    const [offers,setOffers] = useState(null);
    const {setSelected, setEnergy, setStars, userInfo, setStatus, setUserInfo} = useContext(UserContext); //re-renders when logout
    
    useEffect(() => {
        setSelected("offers")
        if (userInfo){
            // getStars()
            // getEnergy()
            fetchOffer()
        }
    }, [userInfo]);

    // fetch and json is async
    const getEnergy = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/get-energy', {credentials: 'include',})
        if (response.ok) {
            response.json().then(energy => {
                setEnergy(energy)
            })
        } else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
        }
    }

    // fetch and json is async
    const getStars = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/get-stars', {credentials: 'include',})
        if (response.ok) {
            response.json().then(stars => {
                setStars(stars)
            })
        } else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
        }
    }

    // fetch and json is async
    const fetchOffer = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/get-offers', {credentials: 'include',})
        if (response.ok) {
            response.json().then(offers => {
                setOffers(offers)
            })
        } else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
        }
    }

    return (
        <div>
            <div className="offers-container">
                <div className="offer-page-title">Offers</div>
                <div className="offer-page-desc">Complete offers to unlock planets</div>
                <div className="offers-inner-container">
                    {offers && offers.length > 0 && offers.map(offer => (
                        <Offer {...offer} key={offer._id} getStars={getStars} getEnergy={getEnergy} fetchOffer={fetchOffer}/>
                    ))}
                    {!offers &&
                        <div className="offer-page-loader" />
                    }
                </div>
            </div>
        </div>

    )

}
import Reward from "../Reward";
import {useEffect, useState, useContext} from "react";
import {UserContext} from "../Context/UserContext";
import "./RewardPage.css"

export default function RewardPage() {
    const [rewards,setRewards] = useState(null);
    const {setSelected, setEnergy, setStars, userInfo, setUserInfo, setStatus, status} = useContext(UserContext); //re-renders when logout
    
    useEffect(() => {
        setSelected("rewards")
        // getStars()
        // getEnergy()
        fetchReward()
    }, [userInfo]);

    // fetch and json is async
    const getEnergy = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/get-energy', {credentials: 'include',})
        if (response.ok) {
            response.json().then(energy => {
                setEnergy(energy)
            })
        } else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
        }
    }

    // fetch and json is async
    const getStars = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/get-stars', {credentials: 'include',})
        if (response.ok) {
            response.json().then(stars => {
                setStars(stars)
            })
        } else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
        }
    }

    // fetch and json is async
    const fetchReward = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/get-rewards', {credentials: 'include',})
        if (response.ok) {
            response.json().then(rewards => {
                setRewards(rewards)
            })
        } else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
        }
    }

    return (
            <div>
                <div className="rewards-container">
                    <div className="rewards-page-title">Rewards</div>
                    <div className="rewards-page-desc">Redeem giftcards with stars</div>
                    {rewards ? 
                        <div className="rewards-inner-container">
                            {rewards.length > 0 && rewards.map(reward => (
                                <Reward {...reward} key={reward._id} getStars={getStars} fetchReward={fetchReward}/>
                            ))}
                        </div>
                        :<div className="reward-page-loader" />}
                </div>
            </div>
    )

}
import "./PlanetPage.css"
import Ring from '../images/asteroids.png'
import { useEffect, useState, useContext } from "react"
import { useParams, Link, Navigate, useNavigate } from "react-router-dom"
import {UserContext} from "../Context/UserContext"
import Planet from "../Planet"
import Loading from "../Loading"
import Button from '../Button/DelayButton'
import Confirm from '../Confirm'
import Error from '../Error'
import ModalNoBg from '../ModalNoBg'
// import { min } from "moment"
// import Ring2 from '../images/ring.svg'

export default function PlanetPage(){
    const {id} = useParams() //access the parameters of the current URL
    const {setSelected, stars, setStars, energy, setEnergy, setStatus, setUserInfo} = useContext(UserContext)
    const [planetInfo, setPlanetInfo] = useState(null)
    const [redirect, setRedirect] = useState(false)
    const [loading, setLoading] = useState(false)
    const [mergeLoading, setMergeLoading] = useState(false)
    const [confirmMsg, setConfirmMsg] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const navigate = useNavigate()
    const [cannotGetError, setCannotGetError] = useState(false)

    useEffect(() => {
        setSelected("planets")
        const fetchPlanet = async () =>{
            const response = await fetch(process.env.REACT_APP_API_URL + `/planet/${id}`, {
            credentials: 'include',}).catch((error) => {
                console.log(error)
            })
            if (response.ok) {
                const planetDoc = await response.json()
                if (planetDoc.status === "created"){
                    setIsNew(true)
                }
                setPlanetInfo(planetDoc)
            } else{
                const error = await response.json()
                if (error === "not logged in"){
                    setStatus("not logged in")
                    setUserInfo(null)
                } else {
                    setCannotGetError({title: "Oops", desc: error})
                }   
            }
        }
        fetchPlanet()
    }, [])

    useEffect(() => {
        if (isNew){
            const interval = setInterval(() => {
                setIsNew(false);
            }, 5000);
          
            return () => clearInterval(interval);
        }
    }, [isNew]);

    const harvest = async () => {
        setLoading(true)
        const response = await fetch(process.env.REACT_APP_API_URL + '/harvest', {
            method: 'POST',
            body: JSON.stringify({ "planetId": id }),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include' // cookies will be considered credentials
        });
        if (response.ok) {
            //json is async
            const message = await response.json()
            getStars()
            setRedirect(true)
            setLoading(false)
            console.log(message)
        } else {
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setErrorMsg({title: "Oops", desc: message})
                setLoading(false)
            }
            // console.log(message)

        }
    }

    // fetch and json is async
    const getStars = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/get-stars', {credentials: 'include',})
        if (response.ok) {
            response.json().then(stars => {
                setStars(stars)
            })
        } else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
            // console.log("not logged in")
        }
    }

    // fetch and json is async
    const getEnergy = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/get-energy', {credentials: 'include',})
        if (response.ok) {
            response.json().then(stars => {
                setStars(stars)
            })
        } else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
            console.log("not logged in")
        }
    }

    const stopLoad = () => {
        setLoading(false)
    }

    if (redirect) {
        return <Navigate to={`/galaxy`}/>
    }

    const mergeHandler = async () => {
        setMergeLoading(true)
        const response = await fetch(process.env.REACT_APP_API_URL + `/planets/count`, {
            credentials: 'include',}).catch((error) => {
                console.log(error)
            })
            if (response.ok) {
                const planetCount = await response.json()
                if (planetCount > 1) {
                    navigate(`/merge/${planetInfo._id}`)
                    // return <Navigate to={`/merge/${planetInfo._id}`}/>
                } else {
                    setErrorMsg({title: "Oops", desc: "You need 2 or more planets to start a merge."})
                }
                setMergeLoading(false)
            } else{
                const message = await response.json();
                if (message === "not logged in"){
                    setStatus("not logged in")
                    setUserInfo(null)
                } else {
                    console.log("error")
                    setMergeLoading(false)
                }
            }
    }

    const backToGalaxy = () => {
        // console.log("galaxy")
        navigate("/galaxy")
    }


    return (
        planetInfo ? 
        <div className="planet-page-container">
            <Confirm confirmMsg={confirmMsg} setConfirmMsg={setConfirmMsg} customClick={harvest} customClose={stopLoad} buttonText="Confirm"/>
            <Error errorMsg={errorMsg} setErrorMsg={setErrorMsg}/>
            <ModalNoBg open={isNew}>
                <div className="new-planet">
                    <div className="title">Planet Unlocked</div>
                    <div className="name">{planetInfo.name}</div>
                </div>
            </ModalNoBg>            

            <div className="planet-page-planet">
                {planetInfo && <Planet planetInfo={planetInfo} fullView={true}/>}
            </div>
            <div className="info">
                <div className="info-inner-container">
                    <div className="name">{planetInfo.name}</div>
                    <div className="title">Total Mass</div>
                    {planetInfo && <div className="number">{planetInfo.coreMass + planetInfo.bonusMass}</div>}
                    <div className="title">Core Mass</div>
                    {planetInfo && <div className="number">{planetInfo.coreMass}</div>}
                    <div className="title">Bonus Mass</div>
                    {planetInfo && <div className="number">{`${planetInfo.bonusMass} (${Math.round((planetInfo.bonusMass / planetInfo.coreMass) * 100)}%)`}</div>}
                    <div className="elements">
                        <div className="elements-title">
                            <span className="elements-number">{planetInfo.fire}</span>Fire
                        </div>
                        <div className="elements-points-bar">
                            <div style={{ width: `${planetInfo.fire}%` }}></div>
                        </div>
                        <div className="elements-title">
                            <span className="elements-number">{planetInfo.water}</span>Water
                        </div>
                        <div className="elements-points-bar">
                            <div style={{ width: `${planetInfo.water}%` }}></div>
                        </div>
                        <div className="elements-title">
                            <span className="elements-number">{planetInfo.earth}</span>Earth
                        </div>
                        <div className="elements-points-bar">
                            <div style={{ width: `${planetInfo.earth}%` }}></div>
                        </div>
                        <div className="elements-title">
                            <span className="elements-number">{planetInfo.air}</span>Air
                        </div>
                        <div className="elements-points-bar">
                            <div style={{ width: `${planetInfo.air}%` }}></div>
                        </div>
                        <div className="elements-title">
                            <span className="elements-number">{planetInfo.life}</span>Life
                        </div>
                        <div className="elements-points-bar">
                            <div style={{ width: `${planetInfo.life}%` }}></div>
                        </div>
                    </div>
                    <div className="buttons">
                        <Button buttonClassName="planet-page-button" 
                            buttonClassNameLoading="planet-page-button planet-page-button-loading" 
                            isLoading={loading} onClick={() => {setConfirmMsg({title: "Harvest", desc: `Are you sure you want to harvest ${planetInfo.name}?`})}}>Harvest</Button>
                        <Button  buttonClassName="planet-page-button" 
                            buttonClassNameLoading="planet-page-button planet-page-button-loading" 
                            isLoading={mergeLoading}
                            onClick={() => {mergeHandler()}}>Merge</Button>
                        {/* <Link to={`/merge/${planetInfo._id}`} className="planet-page-button">Merge</Link> */}
                    </div>
                </div>
            </div>
        </div> :
        <>
            <Error errorMsg={cannotGetError} setErrorMsg={setCannotGetError} customClose={backToGalaxy} customClick={backToGalaxy}/>
            <Loading text="traveling to your planet"/>
        </>
    )
}
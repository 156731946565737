import Reward from "../Reward";
import {useEffect, useState, useContext} from "react";
import {UserContext} from "../Context/UserContext";
import "./RewardPage.css"

export default function RewardDisplayPage() {
    const [rewards,setRewards] = useState(null);
    const {setSelected} = useContext(UserContext); //re-renders when logout
    
    useEffect(() => {
        setSelected("rewards")
        fetchReward()
    }, []);


    // fetch and json is async
    const fetchReward = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/get-rewards')
        if (response.ok) {
            response.json().then(rewards => {
                setRewards(rewards)
            })
        } else{
            console.log("error")
        }
    }

    return (
        <div>
            <div className="rewards-container">
                <div className="rewards-page-title">Rewards</div>
                <div className="rewards-page-desc">Redeem giftcards with stars</div>
                {rewards ? 
                    <div className="rewards-inner-container">
                        {rewards.length > 0 && rewards.map(reward => (
                            <Reward {...reward} key={reward._id} notLoggedIn={true}/>
                        ))}
                    </div>
                    :<div className="reward-page-loader" />}
            </div>
        </div> 
        

    )

}
import Offer from "../Offer";
import {useEffect, useState, useContext} from "react";
import {UserContext} from "../Context/UserContext";
import { Navigate } from "react-router-dom"
import "./OfferPage.css"

export default function OfferDisplayPage() {
    const [offers,setOffers] = useState(null);
    const {setSelected} = useContext(UserContext); //re-renders when logout
    
    useEffect(() => {
        setSelected("offers")
        fetchOfferDisplay()
    }, []);

    // fetch and json is async
    const fetchOfferDisplay = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/get-all-offers')
        if (response.ok) {
            response.json().then(offers => {
                setOffers(offers)
            })
        } else{
            console.log("error")
        }
    }

    return (
        <div>
            <div className="offers-container">
                <div className="offer-page-title">Offers</div>
                <div className="offer-page-desc">Complete offers to unlock planets</div>
                <div className="offers-inner-container">
                    {offers && offers.length > 0 && offers.map(offer => (
                        <Offer {...offer} key={offer._id} notLoggedIn={true}/>
                    ))}
                    {/* an emtpy array is not null */}
                    {!offers &&
                        <div className="offer-page-loader" />
                    }
                </div>
            </div>
        </div>

    )

}
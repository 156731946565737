// import { useContext } from 'react';
// import {DialogContext} from "./Context/DialogContext";
// import ErrorImg from './images/error.svg';
import { useSpring, animated } from "react-spring"
import React from 'react'
import ReactDom from 'react-dom'

export default function ModalNoBg({ open, children, onClose }) {
  const fadeProps = useSpring({ opacity: open ? 1 : 0 });

  // this prevents fade out using useSpring or useTransition
  // one workaround is removing this line and adding another wrapper 
  // around the animated div, but this will create a lot of wrapper under 
  // portal in the dom
  if (!open) return null

  // portal allows this to be on top of all other elements because
  // it is outside root but still be able access everything inside parent
  return ReactDom.createPortal(
    <animated.div id="modal-wrapper" style={fadeProps}>
      {children}
    </animated.div>,
    document.getElementById('portal')
  )
}
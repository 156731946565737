import "./IndexPage.css"
import {useContext, useEffect} from 'react'
import {Link} from "react-router-dom"
import {UserContext} from "../Context/UserContext"
import Logo from '../images/planetmerge.svg'
import AmazonLogoWhite from '../images/amazon_white.svg'
import Amazon from '../images/amazon.svg'
import Uber from '../images/uber.svg'
import Target from '../images/target.svg'
import BestBuy from '../images/best_buy.svg'
import Apple from '../images/apple.svg'
import Coin3D from '../images/coin_3d.svg'
import Planet from '../images/index_page_planet.svg'

export default function IndexPage() {
    const {status, username} = useContext(UserContext)
    const supportEmail = "support@planetmerge.com"

    const contactSupport = () => {
        const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent("Support Request")}`;
        window.location.href = mailtoLink;
    }

    return (
        <div>
            <div className="index-banner">
                <div className="index-banner-text-wrapper">
                    <div className="index-banner-text">Complete simple offers, discover new planets, and earn giftcards.</div>
                    <Link to={ status === "logged in" ? "/offers" : status === "pending" ? "" : "/login" } className="index-banner-button">Start Earning</Link>
                </div>
                <div className="index-banner-image">
                    <div className="index-banner-card-planet">
                        {/* <div className="planet-line">
                            <div className="planet-line-bg"></div>
                        </div> */}
                    </div>
                    <div className="index-banner-card-top">
                        <img src={Logo} alt="Company Logo" className="index-company-logo"/>
                    </div>
                    {/* <div className="index-banner-card-planet-2"></div> */}
                    <img src={Planet} alt="Planet image" className="index-banner-card-planet-2"/>
                    <div className="index-banner-card-bottom">
                        <img src={AmazonLogoWhite} alt="Company Logo" className="index-company-logo"/>
                    </div>
                    <img src={Coin3D} alt="Coin image" className="index-coin"/>
                </div>
            </div>

            <div className="index-main">
                <div className="index-section">
                    <div className="index-section-title">How to play</div>
                    <div className="index-how-to-play">
                        <div className="step">
                            <img src={Planet} alt="Planet image" className="step-image"/>
                            <div className="step-title">Discover Planets</div>
                            <div className="step-text">Complete quick and simple offers like registering for an account to discover new planets</div>
                        </div>
                        <div className="step">
                            <div className="step-image">
                                <div className="step-2-planet-2"></div>
                                <img src={Planet} alt="Planet image" className="step-2-planet-1"/>
                            </div>
                            <div className="step-title">Merge Planets</div>
                            <div className="step-text">Merge to discover rare planets that could be up to 2 times the planets' original mass combined</div>
                        </div>
                        <div className="step">
                            <div className="step-image">
                                <img src={Coin3D} alt="Coin image" className="step-3-coin"/>
                            </div>
                            <div className="step-title">Harvest Planets</div>
                            <div className="step-text">Harvest your planets and turn them into stars. Use stars to redeem giftcards from a wide array of stores</div>
                        </div>
                    </div>
                </div>
                <div className="index-section index-section-giftcards">
                    <div className="index-section-title">Giftcard Options</div>
                    <div className="index-giftcards-container">
                        <div className="index-giftcard">
                            <img src={Amazon} alt="Logo" className="index-giftcard-logo"/>
                        </div>
                        <div className="index-giftcard" style={{backgroundColor: "#E50024"}}>
                            <img src={Target} alt="Logo" className="index-giftcard-logo target"/>
                        </div>
                        <div className="index-giftcard" style={{backgroundColor: "#A2AAAD"}}>
                            <img src={Apple} alt="Logo" className="index-giftcard-logo apple"/>
                        </div>
                        <div className="index-giftcard" style={{backgroundColor: "black"}}>
                            <img src={Uber} alt="Logo" className="index-giftcard-logo"/>
                        </div>
                        <div className="index-giftcard" style={{backgroundColor: "#0a4abf"}}>
                            <img src={BestBuy} alt="Logo" className="index-giftcard-logo best-buy"/>
                        </div>
                    </div>
                </div>
                <div className="index-section" id="index-how-to-play">
                    <div className="index-section-title">Tips and Tricks</div>
                    <div className="index-how-to-play">
                        <div className="step">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="tips-image">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                            </svg>
                            <div className="step-title">Energy</div>
                            <div className="step-text">Energy is required to merge planets, you can earn energy once a day by completing daily check-ins</div>
                        </div>
                        <div className="step">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="tips-image">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                            </svg>

                            <div className="step-title">Bonus Mass</div>
                            <div className="step-text">You can earn Bonus Mass when you merge planets. But be careful, planets with a high percentage of Bonus Mass are more unstable and have a higher risk of losing mass when merged</div>
                        </div>
                        <div className="step">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="tips-image">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z" />
                            </svg>
                            <div className="step-title">Compatibility</div>
                            <div className="step-text">Planets close in mass or have similar composition will have a higher chance of merging into a planet on the higher end of the possbile mass range</div>
                        </div>
                    </div>
                </div>
                <div className="index-section rights-section">
                    <div className="index-support" onClick={contactSupport}>
                        Contact Us
                    </div>
                    <div className="index-rights">
                        © 2023 PlanetMerge, All rights reserved.
                    </div>
                </div>
            </div>
        </div>
    )
}
import {useEffect, useState, useRef, useContext} from "react";
import { Navigate, useParams, Link, useNavigate } from "react-router-dom";
import Planet from "../Planet"
import "./MergePage.css"
import Loading from "../Loading"
import Button from '../Button/DelayButton'
import Error from '../Error'
import { UserContext } from '../Context/UserContext';

export default function MergePage() {
    const {id} = useParams() //access the parameters of the current URL
    const [planet1Doc,setPlanet1Doc] = useState("") 
    const [index,setIndex] = useState(0) // current index in array
    const [newPlanet, setNewPlanet] = useState("")
    const [planets, setPlanets] = useState(null)
    const [compatibility, setCompatibility] = useState("")
    const [upperBound, setUpperBound] = useState("")
    const [lowerBound, setLowerBound] = useState("")
    const [changeUpperBound, setchangeUpperBound] = useState("")
    const [changeLowerBound, setchangeLowerBound] = useState("")
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    const {setEnergy, setStatus, setUserInfo} = useContext(UserContext); //re-renders when logout
    const [cannotGetError, setCannotGetError] = useState(false)
    const navigate = useNavigate()
    const mergeCost = 10
    // const requestPlanet1 = useRef(null)
    // const requestPlanet2 = useRef(null)

    useEffect(() => {
        const fetchPlanets = async () =>{
            const response = await fetch(process.env.REACT_APP_API_URL + `/planets/activated`, {
            credentials: 'include',}).catch((error) => {
                console.log(error)
            })
            if (response.ok) {
                const planetsDoc = await response.json()

                if (planetsDoc.length < 2){
                    setCannotGetError({title: "Oops", desc: "You need two planets to merge."})
                }

                const beforeSplice = planetsDoc.length
                
                for (let i = 0; i < planetsDoc.length; i++) {
                    if (planetsDoc[i]["_id"] === id){
                        setPlanet1Doc(planetsDoc[i])
                        // console.log(planetsDoc)
                        await planetsDoc.splice(i, 1) // remove planet 1
                        if (planetsDoc.length){
                            console.log(planetsDoc)
                            setPlanets(planetsDoc)
                        }
                        break
                    }
                }
                if (beforeSplice === planetsDoc.length){
                    setCannotGetError({title: "Oops", desc: "Planet not availible."})
                }
            } else{
                const message = await response.json();
                if (message === "not logged in"){
                    setStatus("not logged in")
                    setUserInfo(null)
                } else {
                    setCannotGetError({title: "Oops", desc: message})
                } 
            }
        }
        fetchPlanets()
    }, [])

    useEffect(() => {
        if (planet1Doc && planets){
            const estimatation = estimate(planet1Doc, planets[index])

            const totalCoreMass = planet1Doc.coreMass + planets[index].coreMass
            const totalMass = totalCoreMass + planet1Doc.bonusMass + planets[index].bonusMass

            const upper = Math.round(totalCoreMass * ( 1 + estimatation.upperBound))
            const lower = Math.round(totalCoreMass * ( 1 + estimatation.lowerBound))
            setUpperBound(upper)
            setLowerBound(lower)

            const changeUpper = upper - totalMass
            const changeLower = lower - totalMass

            setchangeUpperBound(changeUpper > 0 ? `+${changeUpper}` : changeUpper)
            setchangeLowerBound(changeLower > 0 ? `+${changeLower}` : changeLower)

            if (estimatation.totalMatch > 0.85){
                setCompatibility("Very High")
            } else if (estimatation.totalMatch > 0.7){
                setCompatibility("High")
            } else if (estimatation.totalMatch > 0.4){
                setCompatibility("Moderate")
            } else if (estimatation.totalMatch > 0.2){
                setCompatibility("Low")
            } else {
                setCompatibility("Very Low")
            }
        }
    }, [index, planets, planet1Doc])

    async function merge(){
        setLoading(true)
        const planet1 = id
        const planet2 = planets[index]["_id"]
        // requestPlanet1.current = planet1
        // requestPlanet2.current = planet2
        setNewPlanet("")
        const response = await fetch(process.env.REACT_APP_API_URL + '/merge', {
            method: 'POST',
            body: JSON.stringify({planet1Id: planet1, planet2Id: planet2}),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        } )
            
        if (response.ok) {
            const transDoc = await response.json()
            setNewPlanet(transDoc.planet)
            setLoading(false)
            getEnergy()
            // if (transDoc.planet1Id === requestPlanet1.current && transDoc.planet2Id === requestPlanet2.current){
            //     setNewPlanet(transDoc.postTransaction.planet)
            //     setLoading(false)
            // }
        } else {
            const message = await response.json()
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setErrorMsg({title: "Oops", desc: message})
                setLoading(false)
            }
        }
    }

    const handlePrev = () =>{
        if (index === 0){
            setIndex(planets.length - 1)
        } else {
            setIndex(index - 1)
        }
    }

    const handleNext = async () =>{
        if (index === planets.length - 1){
            setIndex(0)
        } else {
            setIndex(index + 1)
        }        
    }

    

    const estimate = (planet1, planet2) => {
        let massMatch = 0
        const planet2Mass = planet2.coreMass + planet2.bonusMass 
        const planet1Mass = planet1.coreMass + planet1.bonusMass
    
        const coreMass = planet1.coreMass + planet2.coreMass
        if (planet2Mass > planet1Mass){
            massMatch = ( planet1Mass / planet2Mass)
        } else {
            massMatch = ( planet2Mass / planet1Mass)
        }
    
        // expected value 45
        const elementsMatch = Math.pow(100 - ((Math.abs(planet1.fire - planet2.fire) + Math.abs(planet1.water - planet2.water) + 
            Math.abs(planet1.earth - planet2.earth) + Math.abs(planet1.air - planet2.air) + 
            Math.abs(planet1.life - planet2.life)) / 5), 2) / 10000
        // console.log(`mass ${massMatch} elements ${elementsMatch}`)
    
        const totalMatch = (massMatch + elementsMatch) / 2
        // console.log(totalMatch)
    
        const bonusPerCore = (planet1.bonusMass + planet2.bonusMass ) / (planet1.coreMass + planet2.coreMass ) 
        // upperBound = 26% for 0 bonus 99% for 100% bouns
        const upperBound = 1 - Math.pow(0.05, bonusPerCore + 0.1 )
        const lowerBound = - 2 * Math.pow(bonusPerCore, 1.5 ) + bonusPerCore

        return {totalMatch, upperBound, lowerBound}
    }

    // fetch and json is async
    const getEnergy = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/get-energy', {credentials: 'include',})
        if (response.ok) {
            response.json().then(energy => {
                setEnergy(energy)
            })
        } else{
            const message = await response.json()
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
        }
    }

    if (newPlanet) {
        return <Navigate to={`/planet/${newPlanet}`}/>
    }

    const backToGalaxy = () => {
        // console.log("galaxy")
        navigate("/galaxy")
    }

    return (
        // <form onSubmit={merge}>
        //     <input type="text" 
        //         value={planet2}
        //         onChange={ev => setPlanet2(ev.target.value)}/>

        //     <button>Go</button>
        // </form>
        <>
        {(planet1Doc && planets) ? 
            <div className="merge-page">
                <Error errorMsg={errorMsg} setErrorMsg={setErrorMsg} />
                <div className="main">
                    <div className="left">
                        <Link to={`/planet/${planet1Doc["_id"]}`} className="planet-name">{planet1Doc["name"]}</Link>
                        <div className="planet-mass"> 
                            <div>                  
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="planet-mass-icon">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                                </svg>
                                {`${planet1Doc.coreMass + planet1Doc.bonusMass} (${Math.round((planet1Doc.bonusMass / planet1Doc.coreMass) * 100)}% bonus)`}
                            </div>
                        </div>
                        <div className="planet-area">
                            {<Planet planetInfo={planet1Doc} fullView={true}/>}
                        </div>
                    </div>
                    <div className="right">
                        <Link to={`/planet/${planets[index]["_id"]}`} className="planet-name">{planets[index]["name"]}</Link>
                        <div className="planet-mass">                    
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="planet-mass-icon">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                            </svg>
                            {`${planets[index].coreMass + planets[index].bonusMass} (${Math.round((planets[index].bonusMass / planets[index].coreMass) * 100)}% bonus)`}
                        </div>
                        <div className="planet-area">
                            <button className="switch" onClick={() => handlePrev()}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                </svg>
                            </button>
                            <div className="planet-2">
                                {planets.length > 0 && <Planet planetInfo={planets[index]} fullView={true}/>}
                            </div>
                            <button className="switch" onClick={() => handleNext()}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <div className="estimate">Compatibility</div>
                    <div className="estimate-number">{compatibility}</div>
                    <div className="estimate">Total Mass Range</div>
                    <div className="estimate-number estimate-number-last">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="planet-mass-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                        </svg>
                        {`${lowerBound} to ${upperBound}`}
                    </div>
                    <div className="estimate-change">
                        {`(${changeLowerBound} to ${changeUpperBound})`}
                    </div>
                    <Button buttonClassName="merge-button" buttonClassNameLoading="merge-button merge-button-loading" isLoading={loading} onClick={() => {merge()}}>
                        <div className="merge-button-text">
                            Merge for                             
                            <svg viewBox="0 0 24 24" fill="none" strokeWidth={1.5} stroke="currentColor" xmlns="http://www.w3.org/2000/svg" className="merge-energy-icon">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                            </svg> {mergeCost}
                        </div>
                    </Button>
                </div>
            </div> : 
            <>
                <Error errorMsg={cannotGetError} setErrorMsg={setCannotGetError} customClose={backToGalaxy} customClick={backToGalaxy}/>
                <Loading text="traveling to your planet"/>
            </>
        }
        </>
    )
}


import { useContext, useState, useEffect } from "react";
import { useSpring, animated } from "react-spring"
import { Navigate, Link } from "react-router-dom";
import { UserContext } from "../Context/UserContext";
import "./LoginRegister.css"
import Button from "../Button/Button"
import Sent from '../images/success.svg'
import DelayButton from "../Button/DelayButton"
import Logo from "../images/planetmerge.svg"
import Icon from "../images/planetmerge_icon.svg"

export default function RegisterPage(){
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [noName, setNoName] = useState(false);
    const [noEmail, setNoEmail] = useState(false);
    const [noPass, setNoPass] = useState(false);
    const [invalid, setInvalid] = useState("");
    const [resendStatus, setResendStatus] = useState(null);
    const [verifyPage, setVerifyPage] = useState(false);
    const {setStatus, setUserInfo} = useContext(UserContext);

    // Hooks used to fade in/out contents
    const mainProps = useSpring({ opacity: verifyPage ? 0 : 1 });
    const verifyProps = useSpring({ opacity: verifyPage ? 1 : 0 });

    async function resend(){
        setResendStatus("loading")
        const response = await fetch(process.env.REACT_APP_API_URL + '/resend', {
            method: 'POST',
            body: JSON.stringify({email}),
            headers: {'Content-Type': 'application/json'}
        });
        if (response.ok) {
            setResendStatus("success")
        } else {
            setResendStatus("failed")
        }
    }

    async function register(ev){
        ev.preventDefault(); //no freshing

        setLoading(true)
        setNoName(false)
        setNoEmail(false)
        setNoPass(false)
        setInvalid("")

        const userRegex = /^[a-zA-Z0-9]{6,15}$/
        const usernameFormat = userRegex.test(username)
    
        const passRegex = /^[a-zA-Z0-9]{6,15}$/
        const passwordFormat = passRegex.test(password)
    
        const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const emailFormat = emailRegex.test(email) && email.length <= 319

        // cannot use noName or noPass, not updated yet
        // setLoading(true) is not reflected if we don't wait here
        if ( !usernameFormat || !passwordFormat || !emailFormat){
            const timeout = setTimeout(() => {
                !usernameFormat ? setNoName(true) : setNoName(false)
                !passwordFormat ? setNoPass(true) : setNoPass(false)
                !emailFormat ? setNoEmail(true) : setNoEmail(false)
                setLoading(false)
            }, 400);
        
            return () => {
                clearTimeout(timeout);
            };
        }

        const response = await fetch(process.env.REACT_APP_API_URL + '/register', {
            method: 'POST',
            body: JSON.stringify({username,email,password}),
            headers: {'Content-Type': 'application/json'}
        });

        if (response.ok) {
            setInvalid("")
            setLoading(false)
            setVerifyPage(true)

        } else {
            const response_message = await response.json()
            const timeout = setTimeout(() => {
                setInvalid(response_message)
                setLoading(false)
            }, 400);
        
            return () => {
                clearTimeout(timeout);
            };
        }
    }

    return (
        <div className="login-background">
            <div className="login-main">
                <div className="login-left">
                    <div className="login-logo">
                        <Link to="/">
                            <img src={Logo} alt="logo" className="login-logo"/>
                        </Link>              
                    </div>
                    {verifyPage ? 
                        <animated.div style={verifyProps} className="register-sent-wrapper">
                            <div className="register-sent">
                                <img src={Sent} alt="Verification Sent" className="register-sent-img"/>
                                <div className="register-sent-title">Confirmation Email Sent</div>
                                <div className="register-sent-text">Please check you mail box and spam folder for link</div>
                                <div className="register-sent-button-container">
                                    <DelayButton buttonClassName="resend-button" buttonClassNameLoading="resend-button-loading" onClick={resend} isLoading={resendStatus === "loading"}>Resend</DelayButton>
                                    <div className={resendStatus === "success" ? "resend-message-success resend-message-active" : "resend-message-success"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="login-success-icon">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                        <div>Resent to {email.length > 23? email.substring(0, 20) + "..." : email}</div>                              
                                    </div>
                                    <div className={resendStatus === "failed" ? "resend-message-failed resend-message-active" : "resend-message-failed"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="login-error-icon">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                        </svg>
                                        <div>Cannot resend, please try again.</div>
                                    </div>
                                </div>

                            </div>
                        </animated.div>
                        :
                        <animated.div style={mainProps} className="register-wrapper">
                            <form className="register" onSubmit={register}>
                                <div className="register-title">Create new account</div>
                                <div className="login-field-wrapper">
                                    <div className={noName ? "login-txt-field register-txt-field-expand" : "login-txt-field"}>
                                        <input type="text" 
                                            value={username}
                                            onChange={ev => setUsername(ev.target.value)}/>
                                        <span></span>
                                        <label>Username</label>
                                    </div>
                                    <div className={noName ? "login-error login-error-active" : "login-error"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="login-error-icon">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                        </svg> 
                                        Please enter 6-15 numbers or alphabets
                                    </div>
                                </div>
                                <div className="login-field-wrapper">
                                    <div className={noEmail ? "login-txt-field login-txt-field-expand" : "login-txt-field"}>
                                        <input type="text" 
                                            value={email}
                                            onChange={ev => setEmail(ev.target.value)}/>
                                        <span></span>
                                        <label>Email</label>
                                    </div>
                                    <div className={noEmail ? "login-error login-error-active" : "login-error"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="login-error-icon">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                        </svg> 
                                        Invalid email
                                    </div>
                                </div>
                                <div className="login-field-wrapper">
                                    <div className={noPass || invalid ? "login-txt-field login-txt-field-expand" : "login-txt-field"}>
                                        <input type="password" 
                                            value={password}
                                            onChange={ev => setPassword(ev.target.value)}/>
                                        <span></span>
                                        <label>Password</label>
                                    </div>
                                    <div className={noPass ? "login-error login-error-active" : "login-error"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="login-error-icon">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                        </svg> 
                                        Please enter 6-15 numbers or alphabets
                                    </div>
                                    <div className={invalid ? "login-error login-error-active" : "login-error"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="login-error-icon">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                        </svg> 
                                        {invalid}
                                    </div>
                                </div>
                                <div className="register-spacing"></div>
                                <Button
                                    isLoading={loading}>
                                    {/* onClick={handleClick} */}
                                    Continue
                                </Button>
                                <div className="new-to-site">
                                    Already have an account? <Link to="/login" className="login-to-register">Login</Link>
                                </div>
                            </form>
                        </animated.div>
                    }
                </div>
                <div className="login-right">
                    <div className="login-circle1"></div>
                    <div className="login-circle2"></div>
                    <div className="login-circle3"></div>
                    <svg width="223" height="89" viewBox="0 0 223 89" fill="none" xmlns="http://www.w3.org/2000/svg" className="login-ring">
                        <path fillRule="evenodd" clipRule="evenodd" d="M140.781 0.800412C135.57 1.43188 130.27 2.18535 124.904 3.06413C59.6686 13.7492 9.48781 38.9164 12.8226 59.2767C16.1575 79.6369 71.7451 87.4802 136.981 76.7951C171.727 71.1041 202.201 61.3047 222.594 50.3115C202.882 64.4581 167.692 77.4002 126.479 84.1505C60.5439 94.9501 4.16175 85.8109 0.546319 63.7375C-3.06911 41.6641 47.4513 15.0153 113.387 4.21559C122.752 2.68162 131.925 1.54992 140.781 0.800412Z" fill="url(#paint0_linear_498_3779)" fillOpacity="0.37"/>
                        <defs>
                        <linearGradient id="paint0_linear_498_3779" x1="109.725" y1="86.8947" x2="118.683" y2="-0.705251" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white"/>
                        <stop offset="1" stopColor="white" stopOpacity="0"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    <svg width="194" height="338" viewBox="0 0 194 338" fill="none" xmlns="http://www.w3.org/2000/svg" className="login-ring-2">
                        <path fillRule="evenodd" clipRule="evenodd" d="M193.909 155.331C189.919 162.984 185.689 170.702 181.229 178.448C126.967 272.689 59.1344 335.358 29.7216 318.422C0.308852 301.487 20.4535 211.361 74.7159 117.12C103.618 66.9232 136.371 25.6838 165.116 0.660039C131.91 22.8078 91.5861 69.4848 57.3046 129.024C2.46034 224.275 -16.1497 316.376 15.7379 334.736C47.6256 353.097 117.936 290.764 172.78 195.512C180.567 181.988 187.624 168.527 193.909 155.331Z" fill="url(#paint0_linear_498_3780)" fillOpacity="0.37"/>
                        <defs>
                        <linearGradient id="paint0_linear_498_3780" x1="43.3697" y1="153.225" x2="183.427" y2="190.209" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white"/>
                        <stop offset="1" stopColor="white" stopOpacity="0"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    <div className="login-right-content">
                        <img src={Logo} alt="logo" className="login-logo"/>
                    </div>
                </div>
            </div>
        </div>
    )
}
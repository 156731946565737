import Modal from '../Modal'
import './AccountPage.css'
import {useEffect, useState, useRef, useContext} from "react";
import LoadMoreButton from '../Button/DelayButton'
import Success from '../Success'
import Error from '../Error'
import Icon from '../images/planetmerge_icon.svg';
import { UserContext } from '../Context/UserContext';
import {Link} from "react-router-dom"
import Button from "../Button/Button"

export default function AccountPage(){
    const [rating, setRating] = useState({result: 0})
    const [newRating,setNewRating] = useState(0)
    const [ratingLoading, setRatingLoading] = useState(false)
    const [eventLoading, setEventLoading] = useState(false)
    const [desc, setDesc] = useState("");
    const [ratings,setRatings] = useState([])
    const [doubleDamage, setDoubleDamage] = useState(false)
    const [event, setEvent] = useState(null)
    // const [eventComplete, setEventComplete] = useState(false)

    const [transactions,setTransactions] = useState([])
    const headId = useRef(null) // last transaction requested
    const amount = 5 // numbers of transactions to request at a time
    const [reachEnd,setReachEnd] = useState(false)
    const [loading,setLoading] = useState(false)
    const [noTrans,setNoTrans] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)
    const [resending,setResending] = useState(null)
    const [progressValue, setProgressValue] = useState(0)
    const {setUserInfo, userInfo, setStatus, status, setLock, setSelected, energy, setEnergy, stars, setStars} = useContext(UserContext); //re-renders when logout

    useEffect(() => {
        setSelected("account")
        getRating()
        getDoubleDamage()
        getEvent()
    }, []);

    useEffect(() => {
        if (progressValue < Math.floor(stars / 5)){
            setTimeout(() => {
                setProgressValue(progressValue + 1)
            }, 3)
        }
        if (progressValue > Math.floor(stars / 5)){
            setTimeout(() => {
                setProgressValue(progressValue - 1)
            }, 3)
        }
    }, [progressValue, stars])

    // fetch and json is async
    const getRating = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/rating', {credentials: 'include',})
        if (response.ok) {
            response.json().then(rating => {
                setRating(rating)
            })
        } else{
            const message = await response.json()
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
        }
    }

    // fetch and json is async
    const getEvent = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/event', {credentials: 'include',})
        if (response.ok) {
            response.json().then(event => {
                setEvent(event)
            })
        } else{
            const message = await response.json()
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
                setEvent(null)
            }
        }
    }

    useEffect(()  => {
        fetchRatings()
    }, []);

    const fetchRatings = async () =>{
        setLoading(true)
        const response = await fetch(process.env.REACT_APP_API_URL + '/ratings', {
            method: 'POST',
            body: JSON.stringify({headId: headId.current, amount}),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        if (response.ok) {
            const jsonResponse = await response.json()
            // make sure the returned response is not empty and is the one we requested
            if (jsonResponse.headId === headId.current){
                if (jsonResponse.ratings.length) {

                    jsonResponse.ratings.forEach(transaction => {
                        const utcTime = new Date(transaction.createdAt);
                        const localDate = utcTime.toLocaleDateString();
                        transaction.createdAt = localDate
                    })

                    if (headId.current){
                        setRatings([...ratings, ...jsonResponse.ratings])
                    }else {
                        setRatings([...jsonResponse.ratings])
                    }

                    headId.current = jsonResponse.ratings[jsonResponse.ratings.length - 1]["_id"]
                    
                    // console.log(jsonResponse.ratings)
                }
                if (jsonResponse.ratings.length < amount){
                    setReachEnd(true)
                }
                // first request returns nothing means it's empty
                if (!jsonResponse.headId && !jsonResponse.ratings.length){
                    setNoTrans(true)
                }
                setLoading(false)
            }

        } else{
            const message = await response.json()
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                console.log("cannot get ratings")
                setLoading(false)
            }

        }
    }

    const loadMore = () =>{
        fetchRatings()
    }

    async function rate(ev){

        ev.preventDefault() //no freshing
        setRatingLoading(true)

        const response = await fetch(process.env.REACT_APP_API_URL + '/rate', {
            method: 'POST',
            body: JSON.stringify({desc, rating: newRating}),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        if (response.ok) {
            setRatingLoading(false)
            const ratingRecord = await response.json()
            // console.log(ratingRecord.result)
            setRating(ratingRecord)
            headId.current = null
            fetchRatings()
        } else {
            const message = await response.json()
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setErrorMsg({title: "Oops", desc: "Cannot send, please contact support."})
            }
            setRatingLoading(false)
        }

    }

    async function checkEvent(){

        setEventLoading(true)

        const response = await fetch(process.env.REACT_APP_API_URL + '/check-event', {
            credentials: 'include'
        })
        if (response.ok) {
            setEventLoading(false)
            const ratingRecord = await response.json()
            // console.log(ratingRecord.result)
            if (ratingRecord.result){
                setRating(ratingRecord)
                headId.current = null
                fetchRatings()
            }
            getEvent()
            setEvent(null)
            setSuccessMsg({title: "Success", desc: "Reward redeemed!"})
        } else {
            const message = await response.json()
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setErrorMsg({title: "Oops", desc: message})
            }
            setEventLoading(false)
        }

    }

    async function toddleDoubleDamage() {
        const response = await fetch(process.env.REACT_APP_API_URL + '/double-damage', {
            method: 'POST',
            body: JSON.stringify({}),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        if (response.ok) {
            const userDoc = await response.json()
            // console.log(ratingRecord.result)
            setDoubleDamage(userDoc.doubleDamage)
        } else {
            const message = await response.json()
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setErrorMsg({title: "Oops", desc: "error."})
            }
        }
    }

    async function doubleDamageOn() {
        const response = await fetch(process.env.REACT_APP_API_URL + '/set-double-damage', {
            method: 'POST',
            body: JSON.stringify({}),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        if (response.ok) {
            const userDoc = await response.json()
            // console.log(ratingRecord.result)
            setDoubleDamage(userDoc.doubleDamage)
        } else {
            const message = await response.json()
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setErrorMsg({title: "Oops", desc: "error."})
            }
        }
    }

    async function getDoubleDamage() {
        const response = await fetch(process.env.REACT_APP_API_URL + '/double-damage', {
            credentials: 'include'
        })
        if (response.ok) {
            const userDoc = await response.json()
            // console.log(ratingRecord.result)
            setDoubleDamage(userDoc.doubleDamage)
        } else {
            const message = await response.json()
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setErrorMsg({title: "Oops", desc: "error."})
            }
        }
    }

    async function quickSet(rating, desc, turnDoubleDamageOn = false) {
        if (turnDoubleDamageOn) {
            doubleDamageOn()
        }
        setNewRating(rating)
        setDesc(desc)
    }

    return (
        <>
            <Success successMsg={successMsg} setSuccessMsg={setSuccessMsg} buttonText="Okay"/>
            <Error errorMsg={errorMsg} setErrorMsg={setErrorMsg}/>
            <div className="account-page-wrapper">
                <div className="account-page">
                    <div className="account-points-form">
                        <div className="account-points-info">
                            <div className="account-rating-top">
                                <div className="account-points-top-left">
                                    <img src={Icon} alt="Standard Member" className="skeleton account-standard-member-icon"/>
                                    <div className="account-member">
                                        <div className="account-member-name">{userInfo.username}</div>
                                        <div className="damage" onClick={() => {toddleDoubleDamage()}}>
                                            {doubleDamage ? <div className="damage-text">Double Damage</div>:
                                            <div>Standard</div>}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="account-rating-bottom">
                                {rating && <div>{Math.floor(rating.result / 100)}<span className="account-rating-bottom-decimal">{`.${rating.result % 100 < 10 ? "0" : ""}${rating.result % 100}`}</span></div>}
                            </div>
                        </div>
                    </div>

                    <div className="account-rating-form">
                        <form className="rating-form" onSubmit={rate}>
                            <div className='stars-select'>
                                <div className='one-star' style={{ color: newRating >= 100 && "#5de2e7"}} onClick={()=> {setNewRating(100)}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth={0.5} stroke="#5de2e7" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                                    </svg>
                                </div>
                                <div className='one-star' style={{ color: newRating >= 200 && "#5de2e7"}} onClick={()=> {setNewRating(200)}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth={0.5} stroke="#5de2e7" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                                    </svg>
                                </div>
                                <div className='one-star' style={{ color: newRating >= 300 && "#5de2e7"}} onClick={()=> {setNewRating(300)}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth={0.5} stroke="#5de2e7" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                                    </svg>
                                </div>
                                <div className='one-star' style={{ color: newRating >= 400 && "#5de2e7"}} onClick={()=> {setNewRating(400)}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth={0.5} stroke="#5de2e7" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                                    </svg>
                                </div>
                                <div className='one-star' style={{ color: newRating >= 500 && "#5de2e7"}} onClick={()=> {setNewRating(500)}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth={0.5} stroke="#5de2e7" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                                    </svg>
                                </div>

                            </div>
                            <input 
                                className='desc-input'
                                type="text"
                                value={desc}
                                onChange={ev => setDesc(ev.target.value)}/>
                            <Button
                                isLoading={ratingLoading}>
                                {/* onClick={handleClick} */}
                                Send
                            </Button>
                        </form>
                    </div>

                    {event ? ((( event.eventName === "Early Release Program" && rating.result < 170) || event.eventName !== "Early Release Program") && 
                    <div className="account-points-form">
                        <div className="account-rating-info">
                            <div className="title">{event.eventName}</div>
                            <div>{event.eventDesc}</div>
                            {/* <div>{`${event.startTime.toLocaleString()} to ${Date(event.endTime).toLocaleString()}`}</div> */}
                            <Button
                                isLoading={eventLoading} onClick={() => checkEvent()}>
                                Redeem
                            </Button>                            
                        </div>
                    </div>): null}

                    <div className="account-points-form">
                        <div className="account-rating-info">
                            <div className="title">Privileges</div>
                                {rating.result >= 450 ? <div>4.5+ Videos 4hrs</div> : rating.result >= 350 ? <div>3.5+ Videos 3hrs</div> 
                                : rating.result >= 250 ? <div>2.5+ Videos 2hrs</div> : rating.result >= 175 ? <div>1.75+ Videos 1hrs</div> : null}

                                {rating.result >= 480 ? <div>4.8+ Streaming 3ep or 1 movie</div>
                                : rating.result >= 400 ? <div>4.0+ Streaming 2ep</div> : rating.result >= 300 ? <div>3.0+ Streaming 1ep</div> : null}

                                {rating.result >= 300 ? <div>3.0+ Apex Infinity full access</div>
                                : rating.result >= 230 ? <div>2.3+ Apex Infinity shorts access</div> : null}

                                {rating.result >= 498 ? <div>4.98+ premium 2-day excursion with $220 credit</div>
                                : rating.result >= 490 ? <div>4.90+ premium 1-day excursion with $110 credit</div>
                                : rating.result >= 470 ? <div>4.70+ $50 travel credit</div>
                                : rating.result >= 430 ? <div>4.30+ $20 transit and food credit</div> : null}

                                {rating.result < 170 ? <div>1.7- Special Training</div> : null}

                        </div>
                    </div>

                    <div className="account-points-form">
                        <div className="account-rating-info">
                            <div className="title">Rules</div>
                                <div onClick={() => quickSet(500, "Wake time before 9am")} className="clickable">Wake time before 9am 5.0</div>
                                <div onClick={() => quickSet(400, "Wake time before 10am")} className="clickable">Wake time before 10am 4.0</div>
                                <div onClick={() => quickSet(300, "Wake time before 11am")} className="clickable">Wake time before 11am 3.0</div>
                                <div onClick={() => quickSet(200, "Wake time before 12am")} className="clickable">Wake time before 12am 2.0</div>
                                <div onClick={() => quickSet(100, "Wake time after 12am", true)} className="clickable">Wake time after 12am 1.0 + double damage</div>
                                <br />
                                <div onClick={() => quickSet(500, "Sleep time before 1am")} className="clickable">Sleep time before 1am 5.0</div>
                                <div onClick={() => quickSet(400, "Sleep time before 2am")} className="clickable">Sleep time before 2am 4.0</div>
                                <div onClick={() => quickSet(300, "Sleep time before 3am")} className="clickable">Sleep time before 3am 3.0</div>
                                <div onClick={() => quickSet(200, "Sleep time before 4am")} className="clickable">Sleep time before 4am 2.0</div>
                                <div onClick={() => quickSet(100, "Sleep time after 4am", true)} className="clickable">Sleep time after 4am 1.0 + double damage</div>
                                <br />
                                <div onClick={() => quickSet(500, "Task Completed")} className="clickable">Task Completed 5.0</div>
                                <div onClick={() => quickSet(500, "All Tasks Completed")} className="clickable">All Tasks Completed 5.0</div>
                                <div onClick={() => quickSet(100, "Task Failed")} className="clickable">Task Failed 1.0</div>
                                <div onClick={() => quickSet(100, "All Tasks Failed", true)} className="clickable">All Tasks Failed 1.0 + double damage</div>
                                <br />
                                <div onClick={() => quickSet(500, "exercise")} className="clickable">Exercise 5.0</div>
                                <div onClick={() => quickSet(500, "Exercise 3 times a week")} className="clickable">Exercise 3 times a week 5.0</div>
                                <div onClick={() => quickSet(500, "Attend 1 Class or Event")} className="clickable">Attend 1 Class or Event 5.0</div>
                                <div onClick={() => quickSet(500, "apply job")} className="clickable">Apply for 2 Jobs 5.0</div>
                                <div onClick={() => quickSet(500, "leetcode")} className="clickable">Leetcode 5.0</div>
                                <div onClick={() => quickSet(500, "Talk to 2 ppl online")} className="clickable">Talk to 2 ppl online 5.0</div>
                        </div>
                    </div>

                    <div className="transactions-wrapper">
                        <div className="title">Rating History</div>
                        <div className="main">
                            {ratings.length > 0 && ratings.map(pastRating => (
                                <div className="transaction" key={pastRating._id}>
                                    <div className="transaction-main">
                                        <div className="left">
                                            <div className="transaction-title">
                                                {`${Math.floor(pastRating.rating / 100)} (${pastRating.desc})`}
                                            </div>
                                            <div className="time">{pastRating.createdAt}</div>
                                            {pastRating.doubleDamage && <div className="double-damage">Double Damage</div>}
                                        </div>
                                        <div className="right">
                                            {/* energy offer or use */}
                                            <div className='points-wrapper-earn'>
                                                <div>{`${Math.floor(pastRating.result / 100)}.${pastRating.result % 100 < 10 ? "0" : ""}${pastRating.result % 100}`}</div>
                                            </div>  
                                            <div className={ pastRating.change > 0 ?'rating-wrapper-earn' :'rating-wrapper-loss'} >
                                                {pastRating.change > 0 && "+"}
                                                <div>
                                                    {(pastRating.change / 100).toFixed(2)}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}
                            {noTrans && <div className="empty">Nothing yet, start earning some rewards!</div>}
                            {!reachEnd && <LoadMoreButton buttonClassName="load-more-button" buttonClassNameLoading="load-more-button-loading" isLoading={loading} onClick={() => {loadMore()}}>Load More</LoadMoreButton>}
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
import { useSpring, animated } from "react-spring"
import { Navigate, Link, useParams } from "react-router-dom";
import {useState, useEffect} from "react"
import "./ConfirmationPage.css"
import Verfied from '../images/verified.svg'
import Failed from '../images/verify_fail.svg'


export default function ConfirmationPage(){
    const [verify, setVerify] = useState("");
    const [error, setError] = useState("");
    const {token} = useParams();

    // Hooks used to fade in/out contents
    const loadingProps = useSpring({ opacity: verify ? 0 : 1 });
    const successProps = useSpring({ opacity: verify === "success" ? 1 : 0 });
    const failedProps = useSpring({ opacity: verify === "failed" ? 1 : 0 });

    useEffect(() => {
        const fetchConfirmation = async () =>{
            const response = await fetch(process.env.REACT_APP_API_URL + '/confirmation/' + token, {})
            if (response.ok) {
                setVerify("success")
            }else{
                const response_message = await response.json()
                setVerify("failed")
                setError(response_message)
            }
        }
        fetchConfirmation()
    }, [])

    return (
        <div className="confirmation-background">
            {!verify ?
                <animated.div style={loadingProps} className="confirmation-main">
                    <div className="confirmation-loader" />
                </animated.div> :
                verify === "success" ?
                <animated.div style={successProps} className="confirmation-main">
                    <img src={Verfied} alt="Verified" className="confirmation-img"/>
                    <div className="confirmation-title">Email Verified</div>
                    <div className="confirmation-text">Weclome to Skillglance! Please login.</div>
                    <Link to="/login" className="confirmation-login">Login</Link>
                </animated.div> : 
                <animated.div style={failedProps} className="confirmation-main">
                    <img src={Failed} alt="Failed" className="confirmation-img"/>
                    <div className="confirmation-title">Cannot Verify Email</div>
                    <div className="confirmation-text">{error}</div>
                </animated.div>
            }
        </div>

    )
}
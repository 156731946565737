import "./Loading.css"

// status = pending unless server responses
// display loading while pending (needed when refresh page)
export default function Loading ({text}) {
        return <div className='loading-screen'>
            <div className='rocket'>
                <div className='rocket-fire'>
                    <svg viewBox="0 0 190 207" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M95 207L12.7276 51.75L177.272 51.75L95 207Z" fill="#FCD674"/>
                        <path d="M94.4312 144.51L45.1662 51.7503L143.696 51.7503L94.4312 144.51Z" fill="#FA7413"/>
                    </svg>
                </div>
                <div className='rocket-body'>
                    <svg viewBox="0 0 324 650" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M73.6121 98.9027C97.0887 63.3422 118.741 38.8149 158.318 2.93688C160.063 1.34935 161.649 0 161.808 0C161.967 0 165.06 2.61936 168.55 5.71503C196.15 30.4804 216.534 52.4676 235.728 78.1855C283.315 142.163 311.63 215.189 320.83 297.661C325.193 336.555 324.479 383.943 318.848 428.631C310.282 496.577 287.202 574.684 257.063 637.867L251.59 649.297H161.887H72.1844L67.1084 638.661C42.2835 586.669 21.7415 523.645 10.8756 465.938C-15.377 326.554 6.03752 201.219 73.6121 98.9027Z" fill="#D9D9D9"/>
                        <circle cx="162" cy="305" r="77" fill="#5DE2E7"/>
                        <circle cx="161.572" cy="304.572" r="62.8833" fill="#9CEFF2"/>
                    </svg>
                </div>

                <div className='rocket-wing-one'>
                    <svg viewBox="0 0 477 285" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M85.6171 0.0615178C51.56 2.34194 0 67.072 0 67.072C8.81062 136.488 59.9044 282.704 65.0209 283.999C65.1278 284.026 65.4419 283.197 66.0248 281.66C70.3501 270.248 89.4739 219.795 148.512 190.297H148.184L143.108 179.661C118.284 127.669 97.7415 64.6445 86.8756 6.93801C86.4432 4.64202 86.0236 2.34984 85.6171 0.0615178ZM327.736 189.993C387.335 219.448 406.594 270.292 410.936 281.754L410.936 281.754C411.519 283.293 411.833 284.121 411.94 284.094C417.055 282.799 468.132 136.533 476.94 67.0945C476.94 67.0945 424.204 0.844845 390.183 0.00781704C379.382 60.0982 358.795 124.922 333.063 178.867L327.736 189.993Z" fill="#5DE2E7"/>
                    </svg>
                </div>
                <div className='rocket-wing-two'>
                    <svg viewBox="0 0 477 285" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M85.6171 0.0615178C51.56 2.34194 0 67.072 0 67.072C8.81062 136.488 59.9044 282.704 65.0209 283.999C65.1278 284.026 65.4419 283.197 66.0248 281.66C70.3501 270.248 89.4739 219.795 148.512 190.297H148.184L143.108 179.661C118.284 127.669 97.7415 64.6445 86.8756 6.93801C86.4432 4.64202 86.0236 2.34984 85.6171 0.0615178ZM327.736 189.993C387.335 219.448 406.594 270.292 410.936 281.754L410.936 281.754C411.519 283.293 411.833 284.121 411.94 284.094C417.055 282.799 468.132 136.533 476.94 67.0945C476.94 67.0945 424.204 0.844845 390.183 0.00781704C379.382 60.0982 358.795 124.922 333.063 178.867L327.736 189.993Z" fill="#5DE2E7"/>
                    </svg>
                </div>
            </div>
        <div className='loading-text'>{text}</div>
    </div>
}


import { useState, createContext } from "react";

export const UserContext = createContext({});

export function UserContextProvider({children}) {
    const [userInfo, setUserInfo] = useState(null)
    const [status, setStatus] = useState("pending") //logged in, not logged in, or pending
    const [selected, setSelected] = useState("")
    const [stars, setStars] = useState(0)
    const [energy, setEnergy] = useState(0)

    return (
        <UserContext.Provider value={{userInfo, setUserInfo, status, setStatus, selected, setSelected, stars, setStars, energy, setEnergy}}>
            {children}
        </UserContext.Provider>
    )
}

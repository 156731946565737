import { useSpring, animated } from "react-spring"
import {useState, useEffect} from "react"
import "./ButtonStyle.css"

export default function Button({ isLoading, children, ...props }) {

  // Hooks used to fade in/out the loader or the button contents
  const fadeOutProps = useSpring({ opacity: isLoading ? 1 : 0 });
  const fadeInProps = useSpring({ opacity: isLoading ? 0 : 1 });

  return (
    <button {...props} disabled={isLoading ? true : false} className={isLoading ? "button button-loading" : "button"}>
      {isLoading ? (
        <animated.div style={fadeOutProps}>
          <div className="loader" />
        </animated.div>
      ) : (
        <animated.div style={fadeInProps}>{children}</animated.div>
      )}
    </button>
  );
}

import Modal from '../Modal'
import './AccountPage.css'
import {useEffect, useState, useRef, useContext} from "react";
import LoadMoreButton from '../Button/DelayButton'
import Success from '../Success'
import Error from '../Error'
import Icon from '../images/planetmerge_icon.svg';
import { UserContext } from '../Context/UserContext';
import {Link} from "react-router-dom"

export default function AccountPage(){
    const [transactions,setTransactions] = useState([])
    const headId = useRef(null) // last transaction requested
    const amount = 5 // numbers of transactions to request at a time
    const [reachEnd,setReachEnd] = useState(false)
    const [loading,setLoading] = useState(false)
    const [noTrans,setNoTrans] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)
    const [resending,setResending] = useState(null)
    const [progressValue, setProgressValue] = useState(0)
    const {setUserInfo, userInfo, setStatus, status, setLock, setSelected, energy, setEnergy, stars, setStars} = useContext(UserContext); //re-renders when logout

    useEffect(() => {
        setSelected("account")
        getStars()
    }, []);

    useEffect(() => {
        if (progressValue < Math.floor(stars / 5)){
            setTimeout(() => {
                setProgressValue(progressValue + 1)
            }, 3)
        }
        if (progressValue > Math.floor(stars / 5)){
            setTimeout(() => {
                setProgressValue(progressValue - 1)
            }, 3)
        }
    }, [progressValue, stars])

    // fetch and json is async
    const getStars = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/get-stars', {credentials: 'include',})
        if (response.ok) {
            response.json().then(stars => {
                setStars(stars)
            })
        } else{
            const message = await response.json()
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
        }
    }

    useEffect(()  => {
        fetchTransaction()
    }, []);

    const fetchTransaction = async () =>{
        setLoading(true)
        const response = await fetch(process.env.REACT_APP_API_URL + '/get-transactions', {
            method: 'POST',
            body: JSON.stringify({headId: headId.current, amount}),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        if (response.ok) {
            const jsonResponse = await response.json()
            // make sure the returned response is not empty and is the one we requested
            if (jsonResponse.headId === headId.current){
                if (jsonResponse.transactions.length) {
                    headId.current = jsonResponse.transactions[jsonResponse.transactions.length - 1]["_id"]

                    jsonResponse.transactions.forEach(transaction => {
                        const utcTime = new Date(transaction.createdAt);
                        const localDate = utcTime.toLocaleDateString();
                        transaction.createdAt = localDate
                    })
                    setTransactions([...transactions, ...jsonResponse.transactions])
                    console.log(jsonResponse.transactions)
                }
                if (jsonResponse.transactions.length < amount){
                    setReachEnd(true)
                }
                // first request returns nothing means it's empty
                if (!jsonResponse.headId && !jsonResponse.transactions.length){
                    setNoTrans(true)
                }
                setLoading(false)
            }

        } else{
            const message = await response.json()
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                console.log("cannot get transactions")
                setLoading(false)
            }

        }
    }

    const loadMore = () =>{
        fetchTransaction()
    }

    async function logout() {
        const response = await fetch(process.env.REACT_APP_API_URL + '/logout', {
            credentials: 'include',
            method: 'POST',
        })
        if (response.ok) {
            setStatus("not logged in")
            setUserInfo(null)
        } else {
            const message = await response.json()
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                console.log("logout failed")
            }
        }
    }

    async function resend(giftCardId) {
        setResending(giftCardId)
        const response = await fetch(process.env.REACT_APP_API_URL + '/resend-gift-card', {
            method: 'POST',
            body: JSON.stringify({giftCardId}),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        if (response.ok) {
            setSuccessMsg({title: "Success", desc: "Gift card resent!"})
            setResending(null)
        } else {
            const message = await response.json()
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setErrorMsg({title: "Oops", desc: "Cannot send, please contact support."})
                setResending(null)
            }
        }
    }

    return (
        <>
            <Success successMsg={successMsg} setSuccessMsg={setSuccessMsg} buttonText="Okay"/>
            <Error errorMsg={errorMsg} setErrorMsg={setErrorMsg}/>
            <div className="account-page-wrapper">
                <div className="account-page">
                    <div className="account-points-form">
                        <div className="account-points-info">
                            <div className="account-points-top">
                                <div className="account-points-top-left">
                                    <img src={Icon} alt="Standard Member" className="skeleton account-standard-member-icon"/>
                                    <div className="account-member">
                                        <div className="account-member-name">{userInfo.username}</div>
                                        <div className="account-member-email">{userInfo.email}</div>
                                    </div>
                                </div>
                                <div className="logout"onClick={logout}>
                                    <svg className="logout-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                                    </svg>
                                    <div className="logout-text">Logout</div>
                                </div>
                            </div>
                            <div className="account-points-bottom">
                                <div className="account-points-target">{
                                    stars >= 500 ? <Link to={`/rewards`} className="redeem">{"Redeem giftcard >"}</Link> : `${500 - stars} stars to go`
                                }</div>
                                <div className="account-points-bar">
                                    <div style={{ width: `${progressValue > 100 ? 100 : progressValue}%` }}></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="transactions-wrapper">
                        <div className="title">Transaction History</div>
                        <div className="main">
                            {transactions.length > 0 && transactions.map(transaction => (
                                <div className="transaction" key={transaction._id}>  
                                    <div className="transaction-type">{transaction.type}</div>
                                    <div className="transaction-main">
                                        <div className="left">
                                            <div className="transaction-title">
                                            { transaction.type === "offer" && transaction.offer.title }
                                            { transaction.type === "reward" && `$${(transaction.reward.amount / 100).toFixed(2)} ${transaction.reward.title} Giftcard`  }
                                            { transaction.type === "merge" && 
                                                <>
                                                    {`${transaction.planetParent1.name} and ${transaction.planetParent2.name} `}
                                                </>
                                            }
                                            { transaction.type === "harvest" && "Harvest " + transaction.planet.name }
                                            {/* { transaction.type === "merge" && 
                                                <span>
                                                    <span>{`${transaction.planet.name} (Planet Mass`}</span>
                                                    <svg viewBox="0 0 24 24" fill="none" strokeWidth={1.5} stroke="currentColor" xmlns="http://www.w3.org/2000/svg" className="transaction-title-icon">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                                                    </svg>
                                                    <span>{`${transaction.planet.coreMass + transaction.planet.bonusMass})`}</span>
                                                </span>
                                            } */}
                                            </div>
                                            <div className="short-desc">
                                                {transaction.type === "offer" && transaction.offer.short_desc}
                                                {transaction.type === "merge" &&
                                                <>
                                                    {`${transaction.planetParent1.name} Planet Mass\
                                                    ${transaction.planetParent1.coreMass + transaction.planetParent1.bonusMass}`} <br/> {`${transaction.planetParent2.name} Planet Mass\
                                                    ${transaction.planetParent2.coreMass + transaction.planetParent2.bonusMass}`}                                        
                                                </> } 
                                                {transaction.type === "harvest" &&
                                                <>
                                                    {`Core Mass ${transaction.planet.coreMass}`} <br/> {`Bonus Mass ${transaction.planet.bonusMass} (${Math.round((transaction.planet.bonusMass / transaction.planet.coreMass) * 100)}%)`}                                  
                                                </> }  
                                                {/* {transaction.type === "merge" &&
                                                <span>
                                                    <span>{`from ${transaction.planetParent1.name} (Planet Mass`}</span>
                                                    <svg viewBox="0 0 24 24" fill="none" strokeWidth={1.5} stroke="currentColor" xmlns="http://www.w3.org/2000/svg" className="transaction-desc-icon">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                                                    </svg>
                                                    <span>{`${transaction.planetParent1.coreMass + transaction.planetParent1.bonusMass}) and ${transaction.planetParent2.name}(Planet Mass`}</span>
                                                    <svg viewBox="0 0 24 24" fill="none" strokeWidth={1.5} stroke="currentColor" xmlns="http://www.w3.org/2000/svg" className="transaction-desc-icon">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                                                    </svg>  
                                                    <span>{`${transaction.planetParent2.coreMass + transaction.planetParent2.bonusMass})`}</span>                                              
                                                </span> }   */}                                              
                                            </div>
                                        </div>
                                        <div className="right">
                                            {/* planet offer or merge */}
                                            { (transaction.type === "offer" && transaction.offer.type === "planet" || transaction.type === "merge" ) && 
                                                <div className={transaction.planet ? 'points-wrapper-earn' : 'points-wrapper-loss'}>
                                                    <div>
                                                        { transaction.planet ? <div className="transaction-result">{transaction.planet.name}</div> : `${transaction.offer.minMass}-${transaction.offer.maxMass} Mass Planet`}
                                                        { transaction.planet && <div className="planet-details">{`(Planet Mass ${transaction.planet.coreMass + transaction.planet.bonusMass})`}</div>}
                                                    </div>
                                                </div>}
                                            {/* energy offer or use */}
                                            { transaction.energyChange !== 0 && 
                                                <div className={ transaction.energyChange > 0 ?'points-wrapper-earn' :'points-wrapper-loss'} >
                                                    {transaction.energyChange < 0 && "-"}
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="offer-bottom-icon">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                                                    </svg>
                                                    <div>
                                                        { transaction.energyChange > 0 ? + transaction.energyChange : -transaction.energyChange}
                                                    </div>
                                                </div>}
                                            {/* harvest planet or redeem giftcard */}
                                            { (transaction.type === "harvest" || transaction.type === "reward") &&
                                                <div className={ transaction.starsChange > 0 ?'points-wrapper-earn' :'points-wrapper-loss'} >
                                                    {transaction.starsChange < 0 && "-"}
                                                    <svg viewBox="0 0 24 24" fill="none" strokeWidth={1.5} stroke="currentColor" xmlns="http://www.w3.org/2000/svg" className="transaction-desc-icon">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                                                    </svg>
                                                    <div>
                                                        { transaction.starsChange > 0 ? transaction.starsChange : -transaction.starsChange}
                                                    </div>
                                                </div>}
                                            <div className="time">{transaction.createdAt}</div>  
                                            {/* rewards with "confirmed" status in database are not issued yet*/}
                                            <div className="status">{transaction.type === "reward" && transaction.status === "Confirmed" ? "Processing" : transaction.status} 
                                                {transaction.status === "Issued" && transaction.type === "reward" && resending !== transaction.giftCard
                                                && <div className="resend" onClick={() => {resend(transaction.giftCard)}}>Resend</div>}
                                                {resending === transaction.giftCard && <div className="resending">Resending</div>}
                                            </div>                                    
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {noTrans && <div className="empty">Nothing yet, start earning some rewards!</div>}
                            {!reachEnd && <LoadMoreButton buttonClassName="load-more-button" buttonClassNameLoading="load-more-button-loading" isLoading={loading} onClick={() => {loadMore()}}>Load More</LoadMoreButton>}
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
import Asteroids from './images/asteroids.png'
import { useEffect, useState, useContext } from "react"
import { Link} from "react-router-dom"
// import { min } from "moment"
// import Ring2 from '../images/ring.svg'

export default function Planet({planetInfo, fullView, animationDuration, hover, shadow}){
    const totalPlanetMass = planetInfo.coreMass + planetInfo.bonusMass
    const planetSize = fullView ? Math.max( 5 , 20 * (1 - Math.pow(Math.E, - 0.005 * totalPlanetMass ))) // 1 to 20 em
        : Math.max( 15 , 60 * (1 - Math.pow(Math.E, - 0.005 * totalPlanetMass ))) // 2 to 60 em

    // console.log((planetInfo.coreMass + planetInfo.bonusMass) / planetInfo.coreMass)

    // from request
    // const planetMass = 400
    // const planetSize = Math.max( 1 , 30 * (1 - Math.pow(Math.E, - 0.005 * planetMass ))) // 1 to 30 em
    // const mainColor = {r: 200, g: 228, b: 169} // must have 169 and 228 and another in between
    // const [colors,setColors] = useState(null);
    // const [lines,setLines] = useState([]);
    // const type = "air"

    // const getAirColors = (mainColor) => {
    //     let lowestColor = "r"
    //     let highestColor = "r"

    //     if (mainColor["g"] < mainColor[lowestColor]) {
    //         lowestColor = "g"
    //     } else if (mainColor["g"] > mainColor[highestColor]) {
    //         highestColor = "g"
    //     }
    
    //     if (mainColor["b"] < mainColor[lowestColor]) {
    //         lowestColor = "b"
    //     } else if (mainColor["b"] > mainColor[highestColor]) {
    //         highestColor = "b"
    //     }
    
    //     const secondaryColor = {r: "", g: "", b: ""}
    //     const middleColor = ["r", "g", "b"].filter(item => ![lowestColor, highestColor].includes(item))[0]

    //     // const adjustedHigh = mainColor[middleColor] * 0.7 + mainColor[highestColor] * 0.3
    //     // const adjustedMiddle = mainColor[middleColor]  * 0.3 + mainColor[highestColor] * 0.7
        
    //     // swap high and middle
    //     secondaryColor[highestColor] = mainColor[middleColor]
    //     secondaryColor[middleColor] = mainColor[highestColor]
    //     secondaryColor[lowestColor] = mainColor[lowestColor]
    
    //     const planetColor = `radial-gradient(circle at 60% 60%, rgb(${mainColor.r}, ${mainColor.g}, ${mainColor.b}) 50%, rgb(${secondaryColor.r}, ${secondaryColor.g}, ${secondaryColor.b}) 100% )`
    //     // dark
    //     const lineColorOne = {r: (mainColor.r * 1.5 + secondaryColor.r * 0.5) / 2.2, 
    //         g: (mainColor.g * 1.5 + secondaryColor.g * 0.5) / 2.2, 
    //         b: (mainColor.b * 1.5 + secondaryColor.b * 0.5) / 2.2}
        
    //     // light
    //     const lineColorTwo = {r: Math.min(255,(mainColor.r * 1.1 + secondaryColor.r) / 1.9), 
    //         g: Math.min(255,(mainColor.g * 1.1 + secondaryColor.g) / 1.9), 
    //         b: Math.min(255,(mainColor.b * 1.1 + secondaryColor.b) / 1.9)}
        
    //     // medium
    //     const lineColorThree = {r: Math.min(255,(mainColor.r * 1.2 + secondaryColor.r * 0.8) / 2), 
    //         g: Math.min(255,(mainColor.g * 1.2 + secondaryColor.g * 0.8) / 2), 
    //         b: Math.min(255,(mainColor.b * 1.2 + secondaryColor.b * 0.8) / 2)}

    //     const planetGlow = {r: Math.min(255,(mainColor.r * 1.5 + secondaryColor.r * 0.5) / 1.9), 
    //         g: Math.min(255,(mainColor.g * 1.5 + secondaryColor.g * 0.5) / 1.9), 
    //         b: Math.min(255,(mainColor.b * 1.5 + secondaryColor.b * 0.5) / 1.9)}

    //     console.log(`${planetColor}, ${lineColorOne.r}, ${lineColorTwo.r}, ${lineColorThree.r}`)

    //     return {planetColor: planetColor, lineColorOne: lineColorOne, lineColorTwo: lineColorTwo, lineColorThree: lineColorThree, planetGlow: planetGlow}
    // }

    const getLoopWidth = (topPercent) => {
        const bottomPercent = topPercent > 50 ? 100 - topPercent : topPercent
        return Math.sqrt(Math.pow(50,2) - Math.pow(bottomPercent - 50, 2)) * 2.3
    }

    const getLoopHeight = (topPercent) => {
        const bottomPercent = topPercent > 50 ? 100 - topPercent : topPercent
        // last part was adjustment for more 3d appearance
        return Math.sqrt(Math.pow(50,2) - Math.pow(bottomPercent - 50, 2)) * 0.2 * (1 + 0.02 * topPercent)
    }

    const getLineThicknessEm = (lineThicknessUnit) => { return lineThicknessUnit * planetSize / 40 }// adjust for planet size

    const getBorder = (lineThicknessUnit, lineColor) => {
        const lineThicknessEm = getLineThicknessEm(lineThicknessUnit)
        return `rgb(${lineColor.r}, ${lineColor.g}, ${lineColor.b}) ${lineThicknessEm}em solid`
    }

    // const getGlow = (planetGlow) => {
    //     return `inset 0.3em 0.2em 0.7em rgb(255, 255, 255, 0.7), 
    //     inset -0.8em -0.8em 2.5em #000,
    //     -0.5em -0.5em 1.5em rgba(${planetGlow.r}, ${planetGlow.g}, ${planetGlow.b}, 0.7)`
    // }
    
        
    // useEffect( () =>  {
    //     console.log(colors)
    //     const tempLines = []
    //     // completed at creation
    //     if (colors) {for (let i = 0; i < 10; i++){
    //         if (i < 2){
    //             let topPercent = 0
    //             if ( i === 0){
    //                 topPercent = Math.floor(Math.random() * 40) + 21 // 21 to 40
    //             } else {
    //                 topPercent = Math.floor(Math.random() * 40) + 41 // 41 to 80
    //             }
    //             tempLines.push(
    //                 {
    //                     topPercent: topPercent,
    //                     lineThicknessUnit: Math.random() * 0.5 + 2.8, // 2.8 to 3.3
    //                     lineColor: colors.lineColorOne
    //                 }
    //             )
    //         } else if (i < 6){
    //             let topPercent = 0
    //             if ( i === 2){
    //                 topPercent = Math.floor(Math.random() * 20) + 11 // 11 to 20
    //             } else if ( i === 3){
    //                 topPercent = Math.floor(Math.random() * 30) + 21 // 21 to 50
    //             } else if ( i === 4){
    //                 topPercent = Math.floor(Math.random() * 30) + 31 // 31 to 60
    //             } else {
    //                 topPercent = Math.floor(Math.random() * 20) + 61 // 61 to 80
    //             }
    //             tempLines.push(
    //                 {
    //                     topPercent: topPercent, // 1 to 80
    //                     lineThicknessUnit: Math.random() * 1.5 + 1, // 1 to 2.5
    //                     lineColor: colors.lineColorThree
    //                 }
    //             )
    //         } else {
    //             tempLines.push(
    //                 {
    //                     topPercent: Math.floor(Math.random() * 80) + 11, // 11 to 80
    //                     lineThicknessUnit: Math.random() * 0.7 + 0.2, // 0.2 to 0.9
    //                     lineColor: colors.lineColorTwo
    //                 }
    //             )            
    //         }
    //     }}

    //     setLines(tempLines)
    // }, [colors])



    return (
        fullView ?
            planetSize ? <div className="planet-page-planet-container no-selection" style={{width: `${planetSize}em`, height: `${planetSize}em`}}>
                <img src={Asteroids} className="planet-page-asteroids" style={{width: `${planetSize * 1.8}em`, height: `${planetSize * 1.8}em`}}/>
                { planetInfo.rings.length > 0 && planetInfo.rings.map(ring => (
                    <img src={Asteroids} className="planet-page-ring" style={{width: `${planetSize * 1.8}em`, height: `${planetSize * 1.8}em`}}/>
                ))}
                { planetInfo.type === "air" ? <>
                    <div className="planet-page-planet-bg" style={{background: planetInfo.planetColor,}}>
                        { planetInfo.lines.length > 0 && planetInfo.lines.map(line => (
                            <div className="planet-line" style={{top: line.topPercent + "%", width: getLoopWidth(line.topPercent) + "%", 
                                height: getLoopHeight(line.topPercent) + "%", paddingBottom: `${getLineThicknessEm(line.lineThicknessUnit) / 4}em`}}>
                                <div className="planet-line-bg" style={{width: `calc(100% + ${getLineThicknessEm(line.lineThicknessUnit)}em)`
                                    , filter: `blur(${getLineThicknessEm(line.lineThicknessUnit) / 5}em)`, border: getBorder(line.lineThicknessUnit, line.lineColor)
                                    }}></div>
                            </div>
                        ))}
                    </div>
                </> : 
                <div className="planet-page-planet-bg" style={{background: planetInfo.planetColor, boxShadow : planetInfo.planetGlow}}>
                {/* <div className="planet-page-planet-bg"> */}
                </div>}
                
                {/* <div className="planet-page-moving-wrapper">
                    <div className="planet-page-moving">
                        <div className="circle circle-a circle-a1"></div>
                        <div className="circle circle-a circle-a2"></div>

                        <div className="circle circle-b circle-b1"></div>
                        <div className="circle circle-b circle-b2"></div>

                        <div className="circle circle-c circle-c1"></div>
                        <div className="circle circle-c circle-c2"></div>

                        <div className="circle circle-d circle-d1"></div>
                        <div className="circle circle-d circle-d2"></div>
                        <div className="circle circle-d circle-d3"></div>

                        <div className="circle circle-e circle-e1"></div>
                        <div className="circle circle-e circle-e2"></div>
                        <div className="circle circle-e circle-e3"></div>

                        <div className="circle circle-f circle-f1"></div>
                        <div className="circle circle-f circle-f2"></div>
                    </div>
                </div> */}

                { (planetInfo.type === "water" || planetInfo.type === "earth" || planetInfo.type === "life") &&
                <div className="planet-page-moving-wrapper-life" style={{filter: planetInfo.decorationsTint,}}>
                    <div className="planet-page-moving-life">
                        {planetInfo.decorations[0] &&  <img className="land-1" src={`${process.env.REACT_APP_API_URL}${planetInfo.decorations[0]}`} alt="land"></img>}
                        {planetInfo.decorations[1] &&  <img className="land-2" src={`${process.env.REACT_APP_API_URL}${planetInfo.decorations[1]}`} alt="land"></img>}
                        {planetInfo.decorations[2] &&  <img className="land-3" src={`${process.env.REACT_APP_API_URL}${planetInfo.decorations[2]}`} alt="land"></img>}
                        {planetInfo.decorations[3] &&  <img className="land-4" src={`${process.env.REACT_APP_API_URL}${planetInfo.decorations[3]}`} alt="land"></img>}
                        {planetInfo.decorations[4] &&  <img className="land-5" src={`${process.env.REACT_APP_API_URL}${planetInfo.decorations[4]}`} alt="land"></img>}
                        {planetInfo.decorations[5] &&  <img className="land-6" src={`${process.env.REACT_APP_API_URL}${planetInfo.decorations[5]}`} alt="land"></img>}
                        {planetInfo.decorations[6] &&  <img className="land-7" src={`${process.env.REACT_APP_API_URL}${planetInfo.decorations[6]}`} alt="land"></img>}
                        {planetInfo.decorations[7] &&  <img className="land-8" src={`${process.env.REACT_APP_API_URL}${planetInfo.decorations[7]}`} alt="land"></img>}
                    </div>
                    <div className="planet-page-moving-life">
                        {planetInfo.decorations[0] &&  <img className="land-1" src={`${process.env.REACT_APP_API_URL}${planetInfo.decorations[0]}`} alt="land"></img>}
                        {planetInfo.decorations[1] &&  <img className="land-2" src={`${process.env.REACT_APP_API_URL}${planetInfo.decorations[1]}`} alt="land"></img>}
                        {planetInfo.decorations[2] &&  <img className="land-3" src={`${process.env.REACT_APP_API_URL}${planetInfo.decorations[2]}`} alt="land"></img>}
                        {planetInfo.decorations[3] &&  <img className="land-4" src={`${process.env.REACT_APP_API_URL}${planetInfo.decorations[3]}`} alt="land"></img>}
                        {planetInfo.decorations[4] &&  <img className="land-5" src={`${process.env.REACT_APP_API_URL}${planetInfo.decorations[4]}`} alt="land"></img>}
                        {planetInfo.decorations[7] &&  <img className="land-8" src={`${process.env.REACT_APP_API_URL}${planetInfo.decorations[7]}`} alt="land"></img>}
                    </div>
                    {planetInfo.image && <div className="planet-page-image" style={{ background: `url(${process.env.REACT_APP_API_URL}${planetInfo.image})`, filter: planetInfo.imageTint}}></div>}
                    
                </div>}
                    
                {/* { planetInfo.type === "water" && 
                    <>
                        <div className="planet-page-image" style={{ background: planetInfo.image && `url(${process.env.REACT_APP_API_URL}${planetInfo.image})` }}></div>
                        <div className="planet-page-image" style={{ background: planetInfo.image2 && `url(${process.env.REACT_APP_API_URL}${planetInfo.image2})`}}></div>
                    </>
                } */}
                { (planetInfo.type === "fire" || planetInfo.type === "air") && 
                    <>
                        {planetInfo.image && <div className="planet-page-image faster" style={{ background: `url(${process.env.REACT_APP_API_URL}${planetInfo.image})`, filter: planetInfo.imageTint}}></div>}
                        {planetInfo.image2 && <div className="planet-page-image" style={{ background: `url(${process.env.REACT_APP_API_URL}${planetInfo.image2})`, filter: planetInfo.image2Tint}}></div>}
                    </>
                }
                <div className="planet-page-shadow" style={{ backgroundColor: planetInfo.tint, boxShadow : planetInfo.planetShadow }}></div>
                
            </div>:<>no</>
        :
        // small view for galaxy page
        <div className="planet no-selection" style={{fontSize: planetSize, animationDuration: animationDuration, background: planetInfo.planetColor}}>
            {/* <Link to={`/planet/${planetInfo._id}`} className="planet-link"></Link> */}
            <dl className={ planetInfo._id === hover ? "infos infos-active" : "infos"}>
                <dt>{planetInfo.name}</dt>
                <dd>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="planet-mass-icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                    </svg>
                    {planetInfo.coreMass + planetInfo.bonusMass}
                </dd>
            </dl>
            { (planetInfo.type === "water" || planetInfo.type === "earth" || planetInfo.type === "life") &&
                <div className="planet-page-moving-wrapper-life" style={{filter: planetInfo.decorationsTint,}}>
                    <div className="planet-page-moving-life still">
                        {planetInfo.decorations[0] &&  <img className="land-1" src={`${process.env.REACT_APP_API_URL}${planetInfo.decorations[0]}`} alt="land"></img>}
                        {planetInfo.decorations[1] &&  <img className="land-2" src={`${process.env.REACT_APP_API_URL}${planetInfo.decorations[1]}`} alt="land"></img>}
                        {planetInfo.decorations[2] &&  <img className="land-3" src={`${process.env.REACT_APP_API_URL}${planetInfo.decorations[2]}`} alt="land"></img>}
                        {planetInfo.decorations[3] &&  <img className="land-4" src={`${process.env.REACT_APP_API_URL}${planetInfo.decorations[3]}`} alt="land"></img>}
                        {planetInfo.decorations[4] &&  <img className="land-5" src={`${process.env.REACT_APP_API_URL}${planetInfo.decorations[4]}`} alt="land"></img>}
                        {planetInfo.decorations[5] &&  <img className="land-6" src={`${process.env.REACT_APP_API_URL}${planetInfo.decorations[5]}`} alt="land"></img>}
                        {planetInfo.decorations[6] &&  <img className="land-7" src={`${process.env.REACT_APP_API_URL}${planetInfo.decorations[6]}`} alt="land"></img>}
                        {planetInfo.decorations[7] &&  <img className="land-8" src={`${process.env.REACT_APP_API_URL}${planetInfo.decorations[7]}`} alt="land"></img>}
                    </div>
                    {planetInfo.image && <div className="planet-page-image still" style={{ background: `url(${process.env.REACT_APP_API_URL}${planetInfo.image})`, filter: planetInfo.imageTint}}></div>}
                    
                </div>}

            { (planetInfo.type === "fire" || planetInfo.type === "air") && 
                <>
                    {planetInfo.image && <div className="planet-page-image still" style={{ background: `url(${process.env.REACT_APP_API_URL}${planetInfo.image})`, filter: planetInfo.imageTint}}></div>}
                    {planetInfo.image2 && <div className="planet-page-image still" style={{ background: `url(${process.env.REACT_APP_API_URL}${planetInfo.image2})`, filter: planetInfo.image2Tint}}></div>}
                </>
            }
            <div className={"planet-shadow-" + shadow} style={{animationDuration: animationDuration}}></div>
        </div> 
        // :
        // <div className="planet no-selection" style={{fontSize: "10em", animationDuration: animationDuration, background: "#434349"}}>
        //     {/* <Link to={`/planet/${planetInfo._id}`} className="planet-link"></Link> */}
        //     <dl className="infos">
        //     <dt>Planet3</dt>
        //     <dd><span></span></dd>
        //     </dl>
        //     <div className="planet-shadow-right" style={{animationDuration: animationDuration}}></div>
        // </div>
    )
}
// import { useContext } from 'react';
// import {DialogContext} from "./Context/DialogContext";
// import ErrorImg from './images/error.svg';
import { useSpring, animated } from "react-spring"
import React from 'react'
import ReactDom from 'react-dom'

export default function Modal({ open, children, onClose }) {
  const fadeProps = useSpring({ opacity: open ? 1 : 0 });

  // this prevents fade out using useSpring or useTransition
  // one workaround is removing this line and adding another wrapper 
  // around the animated div, but this will create a lot of wrapper under 
  // portal in the dom
  if (!open) return null

  // portal allows this to be on top of all other elements because
  // it is outside root but still be able access everything inside parent
  return ReactDom.createPortal(
    <animated.div id="modal-wrapper" style={fadeProps}>
      <div id="modal-overlay" onClick={onClose}/>
      <div id="modal-box">
        {children}
      </div>
    </animated.div>,
    document.getElementById('portal')
  )
}

// export default function Dialog(){
//     const { dialog, setDialog } = useContext(DialogContext)

//     const handleClose = () => {
//         setDialog(null)
//     }

//     if (! dialog ) {
//         return null
//     }

//     return (
//         <div id="dialog-wrapper">
//             <div id="dialog-overlay" onClick={ () => handleClose() }></div>
//             { dialog.type === "error" &&
//                 <div id="dialog-box">
//                     <button id="dialog-close-button" onClick={ () => handleClose() }>x</button>
//                     <img src={ErrorImg} alt="error image" id="dialog-error-image"/>
//                     <div id="dialog-title">{dialog.title}</div>
//                     <div id="dialog-text">{dialog.text}</div>
//                 </div>
//             }
//             { dialog.type === "offer" &&
//                 <div id="dialog-box">
//                     <button id="dialog-close-button" onClick={ () => handleClose() }>x</button>
//                     <img src={ErrorImg} alt="error image" id="dialog-error-image"/>
//                     <div id="dialog-title">{dialog.title}</div>
//                     <div id="dialog-text">{dialog.text}</div>
//                     <button onClick={ () => dialog.handleEarnButton() }>Earn</button>
//                 </div>
//             }
//         </div>
//     );

// }

import {useEffect, useState, useRef, useContext} from "react";

export default function AdminOfferPage(){
    const [transactions,setTransactions] = useState([])

    useEffect(() => {
        getProcessing()
    }, []);

    // fetch and json is async
    const getProcessing = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/get-processing-offers', {credentials: 'include',})
        if (response.ok) {
            response.json().then(trans => {
                setTransactions(trans)
            })
        } else{
            console.log("not logged in")
        }
    }

    const confirm = async (transId) =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/confirm-offer', {
            method: 'POST',
            body: JSON.stringify({transId}),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        if (response.ok) {
            getProcessing()
            console.log("confirmed")
        } else{
            console.log("not logged in")
        }
    }


    return (
        <div style={{ color: "white", paddingTop: "100px"}}>
            {transactions.length > 0 && transactions.map(transaction => (
                <div key={transaction._id}>  
                    id:
                    <div>{transaction._id}</div>
                    type:
                    <div>{transaction.type}</div>
                    offer:
                    <div>{transaction.offer.title}</div>
                    <div>{transaction.offer.shortDesc}</div>
                    <div>{transaction.offer.minMass}</div>
                    <div>{transaction.offer.maxMass}</div>
                    energyChange:
                    <div>{transaction.energyChange}</div>
                    starsChange:
                    <div>{transaction.starsChange}</div>
                    planet:
                    <div>{transaction.planet}</div>
                    status:
                    <div>{transaction.status}</div>
                    user:
                    <div>{transaction.user}</div>
                    createAt:
                    <div>{transaction.createdAt}</div>
                    updated:
                    <div>{transaction.updatedAt}</div>
                    <button onClick={() => {confirm(transaction._id)}}>confirm</button>
                    ===============
                </div>
            ))}
        </div>
    )
}
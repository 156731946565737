import {useEffect, useState, useRef, useContext} from "react";

export default function AdminGiftCardPage(){
    const [giftCards,setGiftCards] = useState([])

    useEffect(() => {
        getGiftCards()
    }, []);

    // fetch and json is async
    const getGiftCards = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/get-gift-cards', {credentials: 'include',})
        if (response.ok) {
            response.json().then(cards => {
                setGiftCards(cards)
            })
        } else{
            console.log("error")
        }
    }

    const resend = async (giftCardId) =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/resend-gift-card', {
            method: 'POST',
            body: JSON.stringify({giftCardId}),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        if (response.ok) {
            getGiftCards()
            console.log("sent!")
        } else{
            console.log("error")
        }
    }


    return (
        <div style={{ color: "white", paddingTop: "100px"}}>
            {giftCards.length > 0 && giftCards.map(giftCard => (
                <div key={giftCard._id}>  
                    id:
                    <div>{giftCard._id}</div>
                    title:
                    <div>{giftCard.title}</div>
                    amount:
                    <div>{giftCard.amount}</div>
                    sent successfully:
                    <div>{giftCard.sent ? "true" : "false"}</div>
                    <button onClick={() => {resend(giftCard._id)}}>resend</button>
                    ===============
                </div>
            ))}
        </div>
    )
}